import { Component } from '@angular/core';

@Component({
  selector: 'app-lua-xls-financial',
  templateUrl: './lua-xls-financial.component.html',
  styleUrls: ['./lua-xls-financial.component.scss']
})
export class LuaXlsFinancialComponent {

}
