import { Component } from '@angular/core';

@Component({
  selector: 'app-luna-human',
  templateUrl: './luna-human.component.html',
  styleUrls: ['./luna-human.component.scss']
})
export class LunaHumanComponent {

}
