export const environment = {
  production: false,
  keycloarUrl: 'https://auth.yieldvestor.com',
  keycloakRealm: 'demo-yieldvestor',
  keycloakClient: 'yv-public',
  url: 'https://nosi-assist-api.sintaxy.com',
  //keycloakRealm: 'rody',
  //keycloakClient: 'yv-public',
  API_KEY:'AK-c8f7848cc3494fd6f3056af5',
  API_SECRET:'4a8732197a673aac6e1bb4ace3ba855357133cbebe6ea155ae1868963191ec13'
};
