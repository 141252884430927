import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-guide',
  templateUrl: './guide.component.html',
  styleUrls: ['./guide.component.scss']
})
export class GuideComponent {
  isLoggedIn = false;

  constructor(private chatService: ChatService, private router: Router) { }

  ngOnInit(): void {
    this.isLoggedIn = this.chatService.isLoggedIn();
  }
  startExploring() {
    this.router.navigate(['/auth/login']);
  }
}
