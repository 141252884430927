import { Component } from '@angular/core';

@Component({
  selector: 'app-caroussel',
  templateUrl: './caroussel.component.html',
  styleUrls: ['./caroussel.component.scss']
})
export class CarousselComponent {
  items = [
    { src: 'assets/img/icon/dedo.svg', alt: 'Image 1' },
    { src: 'assets/img/icon/Demo.svg', alt: 'Image 2' },
    { src: 'assets/img/Banner.svg', alt: 'Image 3' },

  ];


  currentIndex: number = 0;

  get translateX(): string {
    return `-${this.currentIndex * 100}%`;
  }

  next(): void {
    if (this.currentIndex < this.items.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0;
    }
  }

  prev(): void {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.items.length - 1;
    }
  }
}