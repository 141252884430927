import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss']
})
export class DocumentsComponent {
    @Input() prompts: any[] = [
    ];

    currentIndex: number = 0;
    is_loading:boolean = false;
    show_group_docs: boolean = false;
    is_error:boolean = false;
    errors: any[] = []
    onRefreshCall:any = () => {}
    objectList:any[] = []
    loadCache:any = {}
    objectCache:any = {}
    currentDocOpen:any = null

    constructor(
        private chatService: ChatService,
        public translate: TranslateService
    ) {   
    }

    ngOnInit() {
        this.openFileItem = this.openFileItem.bind(this);
        this.LoadDocuments(true)
    }

    toggleGroupDocs() {
        this.show_group_docs = !this.show_group_docs;
    }

    executeGroup(fileid:any, groupDoc:any) {
        console.info("executeGroup: ", groupDoc);

        const groupName:any = groupDoc.group_name;
        const keyCache = fileid+"#"+groupName;
        const payload = {
            'ops' : 'GenDocGroup',
            'fileid' : fileid,
            'groupName' : groupName,
            'filters' : {},
        }

        this.objectCache[keyCache] = {
            'Status' : 'LOADING'
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // console.info("queryDocumentObject: ", doc_resp)
            const object_:any = doc_resp.object_ || {};
            const obj:any = {
                'Status' : 'LOADED',
                'keyCache' : keyCache,
                '_object' : object_,
            }

            // console.info("object: ", object_);
            if (object_ && object_.Status=="OK") {
                const info:any = object_.info;
                console.info("info: ", info);
                // if (info && (info.Status=="OK"||(info.length&&info.length>0))) {
                //     obj['info'] = info
                // }
            } else {
            }

            this.objectCache[keyCache] = obj;

        }, (error:any) => {
            this.objectCache[keyCache] = {
                'Status' : 'FAILED',
                'Details' : error,
            }
        });
    }

    queryDocumentObject(fileid:any, objectName:any) {
        const keyCache = fileid+"#"+objectName;
        const payload = {
            'ops' : 'QueryDocObject',
            'fileid' : fileid,
            'objectName' : objectName,
            'filters' : {},
        }

        // if(this.objectCache[keyCache]) {
        //     const entry=this.objectCache[keyCache];
        //     // console.info("entry: ", entry);
        //     return;
        // }

        this.objectCache[keyCache] = {
            'Status' : 'LOADING'
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            // console.info("queryDocumentObject: ", doc_resp)
            const object_:any = doc_resp.object_ || {};
            const obj:any = {
                'Status' : 'LOADED',
                'keyCache' : keyCache,
                '_object' : object_,
            }

            // console.info("object: ", object_);
            if (object_ && object_.Status=="OK") {
                const info:any = object_.info;
                console.info("info: ", info);
                if (info && (info.Status=="OK"||(info.length&&info.length>0))) {
                    obj['info'] = info
                }
            } else {
            }

            this.objectCache[keyCache] = obj;

        }, (error:any) => {
            this.objectCache[keyCache] = {
                'Status' : 'FAILED',
                'Details' : error,
            }
        });
    }

    onRefreshDocList() {
        this.objectList = [];
        this.LoadDocuments();
    }

    closeCurrentFile() {
        this.currentDocOpen = null;
    }

    openFileItem(item:any) {
        this.currentDocOpen = null;

        if(this.loadCache[item.fileid]) {
            const entry=this.loadCache[item.fileid];
            this.currentDocOpen = item;

            if (entry.Status && entry.Status=='LOADING') {
            }

            if (entry.Status && entry.Status=='DETAILS_LOAD') {
            }

        } else {
            this.loadCache[item.fileid] = {
                'Status' : 'LOADING'
            }

            const payload = {
                'ops' : 'DescribeFile',
                'fileid' : item.fileid,
                'info_list' : [
                    'blocks',
                    'reports',
                    'doc_cplx'
                ],
                'filters' : {},
            }

            this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
                setTimeout(() => {
                    this.loadCache[item.fileid] = {
                        'Status' : 'DETAILS_LOAD',
                        'Details' : doc_resp,
                    }
                }, 130);

                setTimeout(()=>{
                    this.queryDocumentObject(item.fileid, 'REPORTS')
                }, 150);

            }, (error:any) => {
                this.loadCache[item.fileid] = {
                    'Status' : 'FAILED',
                    'Details' : error,
                }
            });
        }

        setTimeout(()=>{
            this.currentDocOpen = item;
        }, 120);

        // this.objectList = [];
        // this.LoadDocuments();
    }

    LoadDocuments(init_first=false) {
        // console.info("LoadDocuments():");
        // return;
        if (this.is_loading) {
            return;
        }

        this.is_loading = true;
        this.is_error = false;
        this.objectList = [];

        const payload = {
            'ops' : 'ListFiles',
            'filters' : {},
        }

        this.chatService.ExecuteTask("lua/DocSystemAPI", payload, (doc_resp:any) => {
            setTimeout(()=>{
                this.is_loading = false;
                if (doc_resp.hasOwnProperty("Status")) {
                    const Status = doc_resp["Status"];
                    if (Status=="OK" && doc_resp.hasOwnProperty("list")) {
                        const list = doc_resp['list'];
                        this.objectList = list;

                        // if (init_first && list.length>0) {
                        //     setTimeout(()=>{
                        //         this.openFileItem(list[0]);
                        //     }, 25);
                        // }
                    } else {
                    }
                }

            }, 123);

        }, (error:any) => {
            console.info("error: ", error)
            this.is_loading = false
            this.is_error = true
        });
    }
}
