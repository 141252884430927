import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';
@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss']
})
export class NavComponent {
  userPhoto: string | null = 'assets/user-photo.jpg';
  searchControl = new FormControl('');
  options: string[] = ['Home', 'Mail', 'News', 'Finance', 'Sports', 'Entertainment'];
  filteredOptions!: Observable<string[]>; // Usando o operador de asserção de não-nulo

  ngOnInit() {
    this.filteredOptions = this.searchControl.valueChanges.pipe(
      startWith(''),
      map(value => this._filter(value as string)) // Faz uma asserção de tipo para garantir que value é uma string
    );
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    return this.options.filter(option => option.toLowerCase().includes(filterValue));
  }
}