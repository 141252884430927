<div>
    <!--
    <img src="assets/img/apps/ODOO.webp" 
        alt="Odoo Logo" 
        class="w-40 h-50 pull-left " 
    />
    -->
    <p class="mt-4 mb-5"
    (click)="goBack()">
    <span class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
          style="border: 1px solid rgb(0, 82, 126);"> 
        VOLTAR
    </span>
</p>
    <h1 class="text-4xl text-left mb-0">
        DPR 106
    </h1>
    <h2 class="text-left opacity-70">
        <span class="cursor-pointer">Lua Apps</span>
        / 
        <span class="text-[#00527E]">
            DPR Modelo 106
        </span>
    </h2>
  
</div>

<div class="mt-4">  

    <div class="w-full">
        <div class="tab-header flex border-b border-gray-300">
            <div class="tab-header-item cursor-pointer py-2 px-4 bg-gray-200 border border-gray-300 rounded-tl rounded-tr">
                Valida&ccedil;&atilde;o
            </div>
            <div class="tab-header-item cursor-pointer py-2 px-4 border rounded-tl rounded-tr">
                Cria&ccedil;&atilde;o
            </div>
        </div>

        <div class=" p-2 tab-content md:p-10 border border-gray-300 rounded-b">

            <div style="min-height: 300px;background: #f4f4f4;padding:0px;border:1px insert #ccc;">
                <app-native-assistant 
                    [assistantName]="'napps/DPR_106'">
                </app-native-assistant>
            </div>

        </div>
    </div>
</div>


