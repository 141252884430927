import { Component, Input, OnInit  } from '@angular/core';

@Component({
  selector: 'app-tab-component',
  templateUrl: './tab-component.component.html',
  styleUrls: ['./tab-component.component.scss']
})
export class TabComponentComponent {
    @Input() tabs: any = []; 
    @Input() content: any = {}; 
    activeTabIndex = ''; // Índice da aba ativa

    constructor() {
    }

    ngOnInit(): void {
        var tabis='Home';
        if (this.tabs.length>0) {
            tabis = this.tabs[0]
        } else {
            this.tabs = ['Home']            
        }
        this.selectTab(tabis)
    }

    // Método para selecionar a aba
    selectTab(index: any): void {
        this.activeTabIndex = index;
    }

    // Verifica se a aba está ativa
    isActive(index: any): boolean {
        return this.activeTabIndex === index;
    }
}
