import { Component } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-audio',
  templateUrl: './audio.component.html',
  styleUrls: ['./audio.component.scss']
})
export class AudioComponent {
  isRecording: boolean | null = null; // `null` indica que a gravação não foi iniciada ou parada
  mediaRecorder: MediaRecorder | null = null;
  audioChunks: Blob[] = [];
  audioUrl: string | null = null;
  serverAudioUrl: string | null = null;

  constructor(private http: HttpClient) {}

  async startRecording(): Promise<void> {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.mediaRecorder.start();
      this.isRecording = true;

      this.mediaRecorder.ondataavailable = (event) => {
        this.audioChunks.push(event.data);
      };

      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
        const audioUrl = URL.createObjectURL(audioBlob);
        this.audioUrl = audioUrl;
        this.audioChunks = [];
        this.isRecording = false; // Gravação parada
      };
    } catch (error) {
      console.error('Error accessing media devices.', error);
      this.isRecording = null; // Define como `null` se ocorrer um erro
    }
  }

  stopRecording(): void {
    if (this.mediaRecorder) {
      this.mediaRecorder.stop();
    }
  }

  uploadAudio(): void {
    if (this.audioChunks.length > 0) {
      const audioBlob = new Blob(this.audioChunks, { type: 'audio/wav' });
      const formData = new FormData();
      formData.append('file', audioBlob, 'audio.wav');

      this.http.post('/api/upload-audio', formData).subscribe(
        (response: any) => {
          console.log('Audio uploaded successfully', response);
          this.fetchServerAudio(); // Fetch the audio after successful upload
        },
        (error) => console.error('Error uploading audio', error)
      );
    }
  }

  fetchServerAudio(): void {
    this.http.get('/api/get-audio', { responseType: 'blob' }).subscribe(
      (audioBlob: Blob) => {
        this.serverAudioUrl = URL.createObjectURL(audioBlob);
      },
      (error) => console.error('Error fetching audio', error)
    );
  }

  startNewRecording(): void {
    // Limpa o áudio atual e reinicia a gravação
    this.audioUrl = null;
    this.serverAudioUrl = null;
    this.audioChunks = [];
    this.startRecording();
  }
}
