import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';

@Component({
    selector: 'app-generative-ai-iterator',
    templateUrl: './generative-ai-iterator.component.html',
    styleUrls: ['./generative-ai-iterator.component.scss']
})
export class GenAiIteratorComponent implements OnInit {
    @Input() assistantName: string = "genai/Assistant";
    @Input() doc: any;
    @Input() context: string = "NO_CTX";
    @Input() contextId: string = "NO_CTX_ID";
    @Input() inputValue?: string = "";
    @Output() btnClick = new EventEmitter<void>();
    @Output() inputValueChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() appConf: any = null;
    @Input() assistConf: any = null;

    qidx_seq:number = 1;
    is_loading:boolean = false
    is_error:boolean = false

    private subscription: Subscription;
    private currentEditIndex: number | null = null; 

    bufMessages: any[] = [
        /*
        {
            type:"you",
            content: ['hey there luna'],
            perfil: 'assets/img/temp/profile.svg',
        },
        {
            type:"text",
            content: {
                text: "Nao consegui compreender a pregunta. Poderia simplificar"
            },
            perfil: 'assets/img/temp/profile.svg',
        },
        {
            type:"you",
            content: ['okay go on'],
            perfil: 'assets/img/temp/profile.svg',
        },
        */
    ]

    genAiRequest:any = {
        'iterations' : [
            {
                'id':1,
                'q' : 'ITERACAO 001',
                'a' : 'LKASDDSADADASDASD',
            },
        ],
        'curr_idx' : 54,
        'analysis' : {},
        'stats' : {},
        'max_iterations' : 5,
        'details' : {}
    }

    beginConversation() {
        this.qidx_seq += 1
        // console.info("this.bufMessages: ", this.bufMessages);

        var qid = "#"+(this.qidx_seq + 1 + (Math.random()*10)).toFixed()
        const msg = 'Boas,Lua. Como estas?';

        this.bufMessages.push({
            qid : qid,
            content : [msg],
            type : 'you'
        });

        this.sendQuestion(qid, msg, '');
    }

    constructor(
        private chatService: ChatService,
        public translate: TranslateService,
        private router: Router,
    ) {
        this.subscription = this.chatService.editMessage$.subscribe(
            ({ message, index }) => this.setEditInput(message, index) 
        );

        this.resendQuestion = this.resendQuestion.bind(this);
    }

    ngOnInit() {
        console.info("doc: ", this.doc);
    }
    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    /*================================================*/
    /*== Upon this rock we'll build our foundation ==*/
    /*================================================*/
    onInputChange(event: Event): void {
        this.inputValue = (event.target as HTMLInputElement).value;
        this.inputValueChange.emit(this.inputValue);
    }
    handelKeyDown(ev: any) {   
        if (ev.key === 'Enter') {
            const chatmsg = this.inputValue;
            // console.info("chatmsg: ", chatmsg);
            ev.preventDefault();
            this.qidx_seq += 1
            // console.info("this.bufMessages: ", this.bufMessages);
            if (chatmsg) {
                var qid = "#"+(this.qidx_seq + 1 + (Math.random()*10)).toFixed()
                // this.inputValue = ''; // Limpa o valor do input
                // this.btnClick.emit();
                setTimeout(()=>{                
                    var is_sent = this.sendQuestion(qid, chatmsg, '');
                    if (is_sent) {
                        this.bufMessages.push({
                            qid : qid,
                            content : [chatmsg],
                            type : 'you'
                        });
                        this.inputValue = ''; // Limpa o valor do input             
                    }
                }, 25);
            }
        }
    }

    onBtnClick() {
        this.btnClick.emit()
    }

    setEditInput(message: string, index: number) { // Modificado
        this.inputValue = message;
        this.inputValueChange.emit(this.inputValue);
        this.currentEditIndex = index; // Adicionado
    }
    /*================================================*/

    getSamples() {
        return this.bufMessages;
    }

    addIteration(conf:any) {
        var curr_idx = this.genAiRequest['curr_idx'];
        curr_idx += 1
        if (!conf.id) {
            conf.id = curr_idx
        }
        this.genAiRequest['curr_idx'] = curr_idx+1;
        this.genAiRequest['iterations'].push(conf);
    }

    executeIteration(id:any) {
    }

    removeIteration(id:any) {
        if (!confirm("Deseja mesmo remover esta iteracao?")) {
            return;
        }

        var iters = this.genAiRequest['iterations']
        const filter = iters.filter((X:any) => {
            return X.id != id;
        })

        this.genAiRequest['iterations'] = filter;
    }

    getNextQid() {
        this.qidx_seq += 1
        var qid = "#"+(this.qidx_seq + 1 + (Math.random()*10)).toFixed();
        return qid;
    }

    resendQuestion(object:any) {
        var qid = '';
        var fqid = '';
        if (object.content&&object.content.length>0) {
            qid = object.content[0].qid;
            if (object.content.length>1) {
                fqid = object.content[1].fqid;
            }
        } else {
            qid = this.getNextQid();
        }

        const obj = {
            qid: qid,
            fqid: fqid,
            type: "you",
            content: object.text,
            perfil: 'assets/img/temp/profile.svg',
        }

        setTimeout(() => {
            // console.info("RE-SUBMIT QUESTION: ", obj);
            const is_sent = this.sendQuestion(qid, object.text[0], fqid);
            if (is_sent) {
                this.bufMessages.push(obj);
            }
        }, 120);
    }

    sendQuestion(qid:string, chatmsg:string, fqid:string) {
        if (this.is_loading) {
            return false;
        }

        this.is_loading=true;
        this.is_error=false;

        const payload:any = {
            'qid' : qid,
            'question' : chatmsg,
            'ctx' : this.context,
            'ctxId' : this.contextId,
            'fileid' : this.doc.fileid,
            'filename' : this.doc.filename,
            'created_at' : this.doc.tms,
            'created_by' : this.doc.created_by,
            'vocal_e' : true,
            'appConf' : this.appConf,
            'assistConf' : this.assistConf
        }

        if (fqid!==null && fqid.trim().length>0){
            payload['fqid']=fqid;
        }

        const name = this.assistantName;
        this.chatService.ExecuteTask(name, payload, (Resp:any) => {
            setTimeout(()=>{
                this.is_loading=false;
                Resp['qid'] = qid; 
                Resp['fqid'] = qid+"_answer";
                const attach = Resp;
                this.bufMessages.push(attach);
            }, 220);

        }, (error:any) => {
            this.is_error=false;
            this.is_loading=false;
            console.log('Error:', error);
        });

        return true;
    }

    startGenAi() {
        console.info("startGenAi: ", this.genAiRequest);
    }

    uploadFile(event:any) {
        console.info("uploadFile: ", event);
        // this.chatService.onFileSelected(event)
        // this.router.navigate(['/chat']);
    }

    openHelpGuide() {
        this.router.navigate(['/guide']);        
    }

    openMode(mode:any) {
        // console.info("OPEN-MODE: ", mode)
    }

    openDoc(doc:any,i:any) {
        // console.info("openDoc: ", doc, i);
        var tms = doc['tms'];
        var realname = doc['realname'];
        var filename = doc['filename'];
        var docDetailsPrompt = "Detalhes do documento "+filename;
        // this.chatService.onSend(docDetailsPrompt);
        // setTimeout(()=>{
        //     this.router.navigate(['chat']);
        // }, 120);
    }
}

