<div *ngIf="mostrarFormulario">
  <form [formGroup]="anexoForForm" (ngSubmit)="onSubmit()" class="max-w-lg mx-auto">
    <!-- Parte não repetida -->
    <div class="mb-4 pb-4 border-b">
      <h2 class="text-lg font-bold mb-2">Detalhes Gerais</h2>
      <div class="grid grid-cols-2 gap-5">
        <div class="form-field flex items-center">
          <label for="ano" class="mr-2">Ano:</label>
          <input id="ano" formControlName="ano" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
        </div>
        <div class="form-field flex items-center">
          <label for="mes" class="mr-2">Mês:</label>
          <input id="mes" formControlName="mes" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
        </div>
        <div class="form-field flex items-center">
          <label for="cd_af" class="mr-2">Código AF:</label>
          <input id="cd_af" formControlName="cd_af" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
        </div>
        <div class="form-field flex items-center">
          <label for="nif" class="mr-2">NIF:</label>
          <input id="nif" formControlName="nif" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          
        </div>
        
      </div>
    </div>

    <!-- Parte repetida -->
    <div formArrayName="linhas" class="grid grid-cols-1 gap-6">
      <button type="button" (click)="addLinha()" class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Adicionar Linha</button>
      <div *ngFor="let linha of linhas.controls; let i = index" [formGroupName]="i" class="bg-gray-200 rounded-lg p-6 shadow-md">
        <div class="grid grid-cols-2 gap-4">
          <div class="form-field flex items-center">
            <label for="origem{{ i }}" class="mr-2">Origem:</label>
            <input id="origem{{ i }}" formControlName="origem" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="nif{{ i }}" class="mr-2">NIF:</label>
            <input id="nif{{ i }}" formControlName="nif" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="designacao{{ i }}" class="mr-2">Designação:</label>
            <input id="designacao{{ i }}" formControlName="designacao" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="tp_doc{{ i }}" class="mr-2">Tipo de Documento:</label>
            <input id="tp_doc{{ i }}" formControlName="tp_doc" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="num_doc{{ i }}" class="mr-2">Número do Documento:</label>
            <input id="num_doc{{ i }}" formControlName="num_doc" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="data{{ i }}" class="mr-2">Data:</label>
            <input id="data{{ i }}" formControlName="data" type="date" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="vl_fatura{{ i }}" class="mr-2">Valor da Fatura:</label>
            <input id="vl_fatura{{ i }}" formControlName="vl_fatura" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="vl_base_incid{{ i }}" class="mr-2">Valor Base Incidente:</label>
            <input id="vl_base_incid{{ i }}" formControlName="vl_base_incid" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="tx_iva{{ i }}" class="mr-2">Taxa de IVA:</label>
            <input id="tx_iva{{ i }}" formControlName="tx_iva" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="iva_sup{{ i }}" class="mr-2">IVA Suportado:</label>
            <input id="iva_sup{{ i }}" formControlName="iva_sup" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="direito_ded{{ i }}" class="mr-2">Direito a Dedução:</label>
            <input id="direito_ded{{ i }}" formControlName="direito_ded" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="iva_ded{{ i }}" class="mr-2">IVA Dedutível:</label>
            <input id="iva_ded{{ i }}" formControlName="iva_ded" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="tipologia{{ i }}" class="mr-2">Tipologia:</label>
            <input id="tipologia{{ i }}" formControlName="tipologia" type="text" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="form-field flex items-center">
            <label for="linha_dest_mod{{ i }}" class="mr-2">Linha Dest Mod:</label>
            <input id="linha_dest_mod{{ i }}" formControlName="linha_dest_mod" type="number" class="w-full px-3 py-2 border rounded-lg shadow-sm focus:outline-none focus:border-blue-500" />
          </div>
          <div class="flex items-center justify-end mt-4 col-span-2">
            <button type="button" (click)="removeLinha(i)" class="text-red-500 hover:text-red-700 focus:outline-none">Remover Linha</button>
          </div>

        </div>
       
      </div>
      
    </div>

    <!-- Botões -->
    <div class="flex justify-center mt-6 space-x-3">
     
      <button type="submit" [disabled]="!anexoForForm.valid" class="px-4 py-2 bg-blue-500 text-white rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:bg-blue-600">Submeter</button>
    </div>
  </form>
  
</div>
<div *ngIf="!mostrarFormulario">
  <!-- Se mostrarFormulario for false, exibe a resposta -->
  <h3>{{ resposta.answer }}</h3>
  <!-- Exibir ações disponíveis da resposta, se houver -->
  <div *ngIf="resposta.actions && resposta.actions.length > 0" class="flex">
    <ul class="flex">
      <li *ngFor="let action of resposta.actions">
        <button class="cursor-pointer p-2 bg-[#00527E] rounded-xl font-bold text-white m-2" (click)="executarAcao(action.prompt)">{{ action.label }}</button>
      </li>
    </ul>
  </div>
</div>
