import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtomComponent } from '../rounded-icon-buttom/rounded-icon-buttom.component';

import { ChatService } from 'src/app/services/chat.service';
import { FormsModule } from '@angular/forms';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-card-chat',
  standalone: true,
  imports: [
    CommonModule,
    RoundedIconButtomComponent,

    FormsModule,
  ],
  templateUrl: './card-chat.component.html',
  styleUrls: ['./card-chat.component.scss'],
})
export class CardChatComponent implements OnInit {
  @Input() CardChatData: any = {
    cardClass: '',
  };
  @Input() onResendQ: any;
  @Input() text?: any;
  @Input() ogText?: any;
  @Input() type?: any;
  @Input() index?: any;
  @Input() qid?: any;
  @Input() fqid?: any;
  @Input() id?: any;
  @Input() prompts?: any[];
  @Input() content!: any[];
  @Input() iconLeftUrl: string = 'assets/img/quadro.svg';
  @Input() iconLeftClass: string = '!bg-yv-5%-gray  !h-[3rem]  !w-[3rem]  ';
  @Input() iconRightUrl: string = 'assets/img/quadro.svg';
  @Input() iconRightClass?: string = undefined;
  @Input() respId:string = ''; // por terminar (erro ao pegar o id no chat service)
  @Output() editMessageEvent = new EventEmitter<string>();
  editMessage: boolean = false;
  saveMessage: boolean = false;
  isResponseTriggered: boolean = false;
  private respIdSubscription: Subscription | undefined;
  ngOnInit(): void {
    if (this.type === 'you') {
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: 'auto',
      });
    }
    this.respIdSubscription = this.chatService.respId$.subscribe(respId => {
      this.respId = respId;
    });
   
  }
  constructor(
    private chatService: ChatService,
   
  ) {}
  textAreaContent: string = this.text || '';
  textAreaPlaceholder: string = '';
  // Function to adjust the height of the textarea
  adjustTextAreaHeight(event: any): void {
    const textarea = event.target;

    // Set the minimum height for the textarea (e.g., 48px)
    const minHeight = 72;

    // Calculate the new height based on the content and minimum height
    textarea.style.height = 'auto'; // Reset the height to auto
    textarea.style.height = Math.max(minHeight, textarea.scrollHeight) + 'px';
  }
  handleVote(index: number, vote: 'UP' | 'DOWN' | ''): void {
    const contentItem = this.content[index];
    if (contentItem && this.respId) {
      // Verifica se já existe um voto oposto
      if (contentItem.vote === vote) {
        vote = ''; // Define voto como vazio para cancelar o voto
      }
      contentItem.vote = vote;
      this.chatService.updateVote(this.respId, vote);
    }
  }
  handleEditMessage() {
   
    this.chatService.triggerEditMessage(this.ogText[0], this.index); // Modificado
  }
  handleReload() {
    const promptText = this.text.toString(); // Garante que text seja uma string simples
    if (this.onResendQ) {
      this.onResendQ(this)
    } else {
      this.chatService.handleReload(this.index, promptText);
    }
  }
}
