<div div class="overflow-x-auto">
    <table class="text-sm">
        <thead>
            <tr class="bg-gray-300 bg-opacity-60 rounded-2xl">
                <th  class="p-3">Name</th>
                <th  class="p-3">Referencia/FileId</th>
                <th  class="p-3" >Filename</th>
                <th  class="p-3">Path</th>
                <th  class="p-3">Criado</th>
                <th v></th>
            </tr>
        </thead>
        <tbody >
            <tr *ngFor="let doc of objectList" 
                class="cursor-pointer pb-10 hover:bg-[#F0F0F0]" 
                (click)="onSelectCallb(doc)">
                <td  class="p-3" >{{ doc['realname'] }}</td>
                <td  class="p-3">{{ doc['fileid'] }}</td>
                <td  class="p-3"> {{ doc['filename'] }} </td>

                <td>   </td>
                <td class="p-3">{{ doc['tms'] || 'Vazio' }}</td>
                <td></td>
            </tr>
        </tbody>
    </table>
</div>
