<div class="calendario-dias grid grid-cols-7 mt-10 text-center">
    <div>
        <button class="px-2 py-1 bg-blue-500 text-white rounded" (click)="alterarMes(-1)">ant.</button>
    </div>
    <div class="calendario-titulo-mes-ano">
        {{ dataAtual | date : 'MMM yyyy' }}
    </div>
    <div>
        <button class="px-2 py-1 bg-blue-500 text-white rounded" (click)="alterarMes(+1)">prox.</button>
    </div>
</div>

<div class="calendario-dias grid grid-cols-7 gap-2">
    <!-- Renderização dos nomes dos dias da semana -->
    <ng-container *ngFor="let dia of diasCalendario; let i = index">
        <div *ngIf="i < 7" class="text-center">
            <strong>{{ dia | date: 'EEEEE' }}</strong>
        </div>
    </ng-container>

    <!-- Dias do calendário -->
    <div *ngFor="let dia of diasCalendario; let i = index"
        class="calendario-dia border border-gray-300 text-center py-2"
        [class.grid-cols-7]="i % 7 === 0"
        [class.opacity-50]="dataAtual.getMonth() !== dia.getMonth()"
        [class.bg-blue-500]="isDiaAtual(dia)" 
        [class.text-white]="isDiaAtual(dia)">
        {{ dia | date : 'dd' }}
    </div>
</div>