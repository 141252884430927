import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { RoundedIconButtonInterface } from 'src/app/models/ComponentInput';
import { Subscription } from 'rxjs';
import { ChatService } from 'src/app/services/chat.service';
import { ViewportScroller } from '@angular/common';
import { HttpHeaders } from '@angular/common/http';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-user-layout',
  templateUrl: './user-layout.component.html',
  styleUrls: ['./user-layout.component.scss'],
})
export class UserLayoutComponent implements OnInit {
  menuOpen: boolean = false; // Adiciona a variável de estado para o menu

  sideMenu: any[];
  index: number = -1;
  indexOfColapse: number = -1;
  
  url?: string;
 
  private scrollSusbscription: Subscription | undefined;
  currentPrompt: string = '';

  selectedLanguage: string = 'en'; 
  languages = [
    { code: 'en', name: 'En', flag: 'assets/img/en.svg' },
    { code: 'pt', name: 'Pt', flag: 'assets/img/pt.svg' }
  ];
  toggleMenu() {
    this.menuOpen = !this.menuOpen;
  }

  constructor(
    private _router: Router,
    private chatService: ChatService,
    private viewportScroller: ViewportScroller,
    private elementRef: ElementRef,
    private languageService: LanguageService  // Inject the language service
  ) {
    this.url = _router.url.split('/')[1];
    this.sideMenu = [
      {
        route: '/home',
        baseIcon: 'assets/img/sideIcon/menuHomeBlank.svg',
        activeIcon: 'assets/img/sideIcon/menuHomeGlow.svg',
        whiteIcon: 'assets/img/HomeWhite.svg',
        title: 'Home',
        expanded: false,
      },
      {
        route: '/luaxapps',
        name: 'Lua Apps ',
        baseIcon: 'assets/img/icon/copy.svg',
        activeIcon: 'assets/img/icon/copy-Azul.svg',
        whiteIcon: 'assets/img/icon/copy-Branco.svg',
        title: 'LuaApps',
        expanded: false,
      },
    /*  {
        route: '/dashboard',
        name: 'Dashboard',
        baseIcon: 'assets/img/icon/element-2.svg',
        activeIcon: 'assets/img/icon/element-1.svg',
        whiteIcon: 'assets/img/ChatWhite.svg',
        title: 'Dashboard',
        expanded: false,
      },*/
      {
        route: '/chat',
        name: 'Chat',
        baseIcon: 'assets/img/icon/Chat escuro.svg',
        activeIcon: 'assets/img/icon/Chat laranja.svg',
        whiteIcon: 'assets/img/ChatWhite.svg',
        title: 'Chat',
        expanded: false,
      },
      //{
      //  route: '/fatura',
      //  name: 'Lista',
       // baseIcon: 'assets/img/icon/Lista escura.svg',
      //  activeIcon: 'assets/img/icon/Lista laranja.svg',
      //  title: 'Lista',
      //  expanded: false,
      //},
     
      {
        route: '/guide',
        name: 'Guia & Ajuda',
        baseIcon: 'assets/img/icon/info-circle.svg',
        activeIcon: 'assets/img/icon/info-circleWh.svg',
        whiteIcon: 'assets/img/.svg',
        title: 'Guia&Ajuda',
        expanded: false,
      },
      
    /*   {
        route: '/reports',
        name: 'Relatorios',
        baseIcon: 'assets/img/icon/Chat escuro.svg',
        activeIcon: 'assets/img/icon/Chat laranja.svg',
        whiteIcon: 'assets/img/ChatWhite.svg',
        title: 'Relatorios',
        expanded: false,
      },
     
      {
        route: '/documents',
        name: 'Documentos ',
        baseIcon: 'assets/img/icon/Chat escuro.svg',
        activeIcon: 'assets/img/icon/Chat laranja.svg',
        whiteIcon: 'assets/img/ChatWhite.svg',
        title: 'Documentos',
        expanded: false,
      },*/
    ];
  }
  chatFooter: boolean = false;
  ngOnInit(): void {
    this.chatFooter = !this._router.url.includes('/assistant/chat');
    this.selectedLanguage = this.languageService.getCurrentLanguage();
    
  }
  onMenuClick(i: number, question: any) {
    this.index = i;

    this.chatService.sendData(question);
    // this._router.navigate([this.sideMenu[i].route]);
  }
  fetchData(p: any, headers: HttpHeaders) {
    // this.chatService.setData(samples);
    // Access the JSON array

    if (p.q) this.onSend(p.q);
    else this.chatService.loading = false;
  }

  selectedTab: string = 'youCanAsk';
  handlePayment() {
    if (this.selectedTab === '') {
      this.selectedTab = 'youCanAsk';
    } else {
      this.selectedTab = '';
    }
  }
  handleSuggestions() {
    if (this.selectedTab !== 'youCanAsk') {
      this.selectedTab = 'youCanAsk';
    }
  }
  onSend(prompt?: string) {
  
    if (prompt?.trim() != '') {
      if (this.chatService.loading) {
        return;
      }
      this.chatService.onSend(prompt);
      this.viewportScroller.scrollToAnchor('inputchat');
      const inputElement = document.querySelector('.input-container input');
      if (inputElement) {
        (inputElement as HTMLInputElement).value = '';
      }
      this.currentPrompt = ''; 
      if(this.url=='') this._router.navigate(['/chat']);
    }
  }
  scrollToBottom() {}
  getSamples() {
    return this.chatService.samples;
  }
  logout() {
    localStorage.removeItem("AUTH_CREDS");
    this._router.navigate(['/auth/login']); 
  }
  switchLanguage(language: string) {
    this.languageService.switchLanguage(language);
  }

  onLanguageChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.languageService.switchLanguage(selectElement.value);
  }
  getFlag(code: string): string {
    const lang = this.languages.find(language => language.code === code);
    return lang ? lang.flag : '';
  }
}
