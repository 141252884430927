import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoundedIconButtonInterface } from 'src/app/models/ComponentInput';

@Component({
  selector: 'app-rounded-icon-buttom',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './rounded-icon-buttom.component.html',
  styleUrls: ['./rounded-icon-buttom.component.scss'],
})
export class RoundedIconButtomComponent implements OnInit {
  @Input() buttonData: RoundedIconButtonInterface = {
    iconUrl: '',
    isDotVisible: false,
    btnClass: '',
    iconClass: '',
  };

  @Output() btnClick = new EventEmitter<void>();

  constructor() {}

  ngOnInit(): void {}

  onBtnClick() {
    this.btnClick.emit();
  }
}
