import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-fatura',
  templateUrl: './document-list.component.html',
  styleUrls: ['./fatura.component.scss']
})
export class FaturaComponent {
    @Input() onSelect:any = (item:any) => {}
    @Input() objectList:any[] = []

    constructor(private router: Router) {
    }

    onSelectCallb(item:any) {
        if (this.onSelect) {
            try {
                this.onSelect(item)                
            } catch (e) {
                console.error("E: ", e);
            }
        }
    }

    goToDetails(){
        this.router.navigate(['/fatura/details/1']);
    }
}
