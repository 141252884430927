<!-- welcome.component.html -->
<div class="grid grid-cols-1 lg:grid-cols-2 gap-4 space-x-2">
  <div class="p-8 md:p-36">
    <div class="flex flex-col lg:flex-row justify-center items-center gap-2 mb-6">
      <img src="assets/img/im blue.jpg" alt="Logo" class="rounded-full w-24 h-24 border-4 border-blue-300 mb-4" />
      <h1 class="text-6xl font-extrabold ">Welcome</h1>
    </div>
    <p class="text-lg text-left  mb-4">
      Olá!!
    </p>
    <p class="text-lg  mb-6 text-justify">
      Sou Lua, sua assistente virtual, aqui para ajudar com informações e facilitar suas tarefas diárias. Seja
      bem-vindo(a) e conte comigo para o que precisar! Se é novo(a) por aqui, ajuste a Lua às suas necessidades ou leia
      o Guia & Ajuda para aproveitar ao máximo.
    </p>

    <div class=" flex justify-center space-x-2 ">
      <button (click)="goToGuide()"
        class="px-4 py-2 text-blue-600 border border-blue-600 hover:bg-blue-700 rounded-3xl transition duration-300">
        Guia
      </button>
      <button (click)="startExploring()"
        class="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-3xl transition duration-300">
        Login
      </button>
    </div>
  </div>
  <div class=" hidden lg:flex  flex-row items-center justify-center">
    <img src="assets/img/icon/Rectangle.svg" alt="" class="w-15 h-15 mb-4 mt-32">
    <img src="assets/img/icon/banner2.svg" alt="" class="w-15 h-[90%]">
  </div>
</div>