<app-user-layout>
  <div class="container mx-auto p-4">
    <h1 class="text-3xl font-semibold mb-6 text-gray-800">My Account</h1>

    <div class="bg-white p-6 rounded-lg shadow-md flex flex-row gap-10 relative">
      <div class="flex flex-col items-center mb-6 md:flex-row md:items-start">
        <div class="relative flex-shrink-0">
          <div class="mt-4 md:mt-0 md:ml-6 flex-1 text-center md:text-left mb-10">
            <div>
              <h2 class="text-xl font-semibold text-gray-700">Profile Information</h2>
              <p class="text-sm text-gray-500">Update your profile details below.</p>
            </div>
          </div>
          <img src="assets/img/im blue.jpg" alt="Profile Picture"
            class="w-36 h-36 rounded-full border-8 border-blue-200 cursor-pointer" (click)="triggerFileUpload()">
          <input type="file" class="hidden" #fileInput (change)="onFileSelected($event)">
          <img src="assets/img/icon/Caneta.svg" alt="Edit Icon"
            class="absolute bottom-0 right-32 w-8 h-8 cursor-pointer" (click)="triggerFileUpload()">
        </div>
      </div>

      <form (ngSubmit)="saveChanges()" class="w-full">
        <button type="submit"
          class="absolute top-0 right-0 mt-4 mr-4 border border-blue-800 text-blue-800 px-6 py-2 rounded-2xl hover:bg-blue-600">
          Save Changes
        </button>
        <div class="grid grid-cols-1 md:grid-cols-2 gap-4 mt-20 ml-7">
          <div class="mb-4">
            <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
            <input id="name" name="name" type="text" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm p-2 bg-[#00343508]"
              required>
          </div>

          <div class="mb-4">
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input id="email" name="email" type="email" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm p-2 bg-[#00343508]"
              required>
          </div>

          <div class="mb-4">
            <label for="phone" class="block text-sm font-medium text-gray-700">Phone</label>
            <input id="phone" name="phone" type="tel" class="mt-1 block w-full border-gray-300 rounded-md shadow-sm p-2 bg-[#00343508]">
          </div>

          <div class="mb-4">
            <label for="address" class="block text-sm font-medium text-gray-700">Address</label>
            <input id="address" name="address" type="text"
              class="mt-1 block w-full border-gray-300 rounded-md shadow-sm p-2 bg-[#00343508]">
          </div>

          <div class="mb-4">
            <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
            <input id="password" name="password" type="password"
              class="mt-1 block w-full border-gray-300 rounded-md shadow-sm p-2 bg-[#00343508]">
          </div>
        </div>
      </form>
    </div>
  </div>
</app-user-layout>
