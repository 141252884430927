<h1 class="text-2xl font-bold mb-4">My Portfolio</h1>

<table class="w-full border-collapse">
  <thead>
    <tr class="bg-gray-100">
      <th class="px-4 py-2 border-b">Symbol</th>
      <th class="px-4 py-2 border-b">Last Price</th>
      <th class="px-4 py-2 border-b">Change</th>
      
      <th class="px-4 py-2 border-b">% Change</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let item of portfolioData; let i = index" [ngClass]="{'bg-gray-50': i % 2 === 0}">
      <td class="px-4 py-2 border-b text-blue-500">{{ item.symbol }}</td>
      <td class="px-4 py-2 border-b">{{ item.lastPrice }}</td>
      <td class="px-4 py-2 border-b" [ngClass]="{'text-red-500': item.change.startsWith('-') , 'text-green-500': !item.change.startsWith('-')}">
        {{ item.change }}
      </td>
      <td class="px-4 py-2 border-b" [ngClass]="{'text-red-500': item.percentChange.startsWith('-') , 'text-green-500': !item.percentChange.startsWith('-')}">
        {{ item.percentChange }}
      </td>
    </tr>
  </tbody>
</table>
