import {
  Component,
  ElementRef,
  Input,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';


@Component({
  selector: 'app-responses',
  templateUrl: './responses.component.html',
  styleUrls: ['./responses.component.scss'],
  // standalone: true,
  // imports:[FaturaDetailsComponent]
})
export class ResponsesComponent {
    constructor(
        private chatService: ChatService,
        private renderer: Renderer2
    ) {}
    @ViewChild('chatContainer', { static: false }) chatContainer!: ElementRef;

    ngAfterViewChecked() {
    }

    prompts: any[] = [
        { prompt: "Analise a fatura", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
        { prompt: "Detalhes da fatura", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
        { prompt: "Detalhes da cliente", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
        { prompt: "Analise os detalhes de pagamento ", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
    ]
    prompts_v2: any[] = [
        { prompt: "Analise os detalhes do fornecedor ", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
        { prompt: "Analise os detalhes do banco ", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
        { prompt: "Listagem dos artigos/produtos ", url: '/assistant/chat', title: "Learn", img: "assets/img/icon/assistantCircuit.svg"  },
    ]

    @Input() content: any[] = [];
    @Input() widget: boolean = false;
    
    runPrompt(prompt: string) {
        this.chatService.onSend(prompt);
    }
    resendPrompt(item:any) {
        console.info("resendPrompt: ", item)        
    }

    executeOdooApi(apin:string, item:any) {
    }
    createSupplier(item:any) {
        const apin = 'create_partner';
        const Report:any = item.report;

        if (Report.supplier_details&&Report.supplier_details.name) {
            this.chatService.createSupplier({
                'ops':apin,
                'reqObj' : {
                    'docname' : item.docname,
                    'name': Report.supplier_details.name,
                    'email': Report.supplier_details.email,
                    'phonenumber': Report.supplier_details.phonenumber,
                    'address': Report.supplier_details.address,
                },
            })
        }
    }
    createInvoice(item:any) {
        const apin = 'create_invoice';
        const Report:any = item.report;
        console.info("item: ", item);

        if (Report.supplier_details&&Report.supplier_details.name) {
            this.chatService.createInvoice({
                'reqObj' : {
                    'docname' : item.docname,
                    'name': Report.supplier_details.name,
                    'delivery_date' : "2030-06-25",
                    // 'partner_bank_id' : 1,
                    'ref' : item.docname
                },
            })
        }
    }
    executeAction(item:any, action:any) {
        this.chatService.onSend(action.prompt);
    }
    addObj(obj: string) {
        this.chatService.pushObj(obj);
    }
    isChatLoading() {
        return this.chatService.loading;
    }
    
}
