import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-native-odoo',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input() appVersion: any = '0.0.1-SNAPSHOT';
    @Input() appPage: any = 'index';
    filterForm: FormGroup;

    is_loading:boolean = false
    is_error:boolean = false
    is_ready:boolean = false
    is_created:boolean = false
    
    onRefreshCall:any = () => {}
    objectList:any[] = []
    loadCache:any = {}
    objectCache:any = {}
    allow_add_bill:boolean = false
    is_xml_model_ready:boolean = false
    model106Conf:any = null
    Object: any = window.Object;
    xmlReport: any = null
    is_filter_error:boolean = false;
    is_timeout: boolean = false;
    is_loading_filter:boolean = true;
    is_taking_long:boolean = false;

    faqQuestions = [
        { 
            question: 'Como criar modelos 106 apartir de faturas no ODOO', 
            answer: 'Você pode enviar faturas para o Lua de duas maneiras: pelo chat, anexando o arquivo ou texto da fatura, ou por e-mail, enviando o arquivo para o endereço de e-mail designado do Lua.', 
            open: false,
        },
        { 
            question: 'Pesquisar por parceiros', 
            answer: 'Você pode enviar ', 
            open: false,
        },
        { 
            question: 'Pesquisar por faturas', 
            answer: 'Você pode enviar ', 
            open: false 
        },
        { 
            question: 'Pesquisar por produtos', 
            answer: 'Você pode enviar ', 
            open: false 
        },
    ]

    constructor(
        private router: Router,
        private fb: FormBuilder,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
        this.filterForm = this.fb.group({
            query: [null, Validators.required],
            state: ['draft', Validators.required],
        });

    }

    ngOnInit() {
        this.InitApp()
    }

    filterInvoices() {
        const form = this.filterForm;
        this.is_filter_error = false;

        if (form.valid) {
            this.is_loading_filter = true;
            const formData = form.value;
            const query = formData.query.trim();
            const state = formData.state.trim();
            console.info("Form is valid: ", query, state);

            const filterx=[
                '&',
                ['move_type','=','out_invoice'],
                ['state', '=', state]
            ]

            if (query.length>0) {
                filterx.push('&', ['invoice_partner_display_name', 'ilike', query])
            }

            this.FilterInvoices(filterx);
        } else {
            // this.is_filter_error = true;
            // this.is_loading_filter = false;
        }
    }

    InitApp() {
        this.is_loading = true;
        setTimeout(()=>{
            this.FetchInvoices(true);
        }, 569);
    }

    addToList(invoice:any) {
        // console.info("AddTOList: ", invoice)
        if (!this.model106Conf.input_bills[invoice.id]) {        
            this.model106Conf.input_bills[invoice.id] = invoice;
            this.model106Conf.count_c += 1;
        }
        invoice.is_add = true;
        invoice.is_deleted = false;
    }

    removeFromList(Key:any) {
        // if (!con)
        console.info("RemoveFromList: ", Key)
        this.model106Conf.input_bills[Key].is_deleted = true;
        this.model106Conf.input_bills[Key].is_add = false;
        if (this.model106Conf.count_c>0) {
            this.model106Conf.count_c -= 1;
        }
    }

    beginModel106() {
        this.allow_add_bill=true;
        this.model106Conf = {
            id:'ADSADs',
            count_c: 0,
            input_bills: {},
            header : {}
        }
    }

    cancelModel106() {
        this.allow_add_bill=false;
        this.model106Conf = null;
        this.is_xml_model_ready=false;
        this.xmlReport=false;

        this.objectCache.invoices.records.forEach((Invoice:any) => {
            Invoice.is_deleted=false;
            Invoice.is_add=false;
        })
    }

    backModel106() {
        this.is_xml_model_ready=false;
        this.xmlReport=false;
    }

    continueModel106() {
        if (!this.model106Conf) {
            return;
        }

        this.is_xml_model_ready = false;

        const model106 = this.model106Conf;
        const bills = Object.keys(model106.input_bills)
        // if ()
        console.info("bills: ", bills);
        if (bills.length==0) {
            return;
        }

        // this.allow_add_bill=false;
        console.info("model106: ", model106);

        setTimeout(()=>{
            this.is_xml_model_ready=true;
            const THE_DATE = "2024-07-24";
            const RowList:any[] = [];

            Object.keys(model106.input_bills)
            .forEach((K) => {
                const BILLING = model106.input_bills[K];
                if (!BILLING || BILLING.is_deleted) {
                    if (BILLING.is_deleted) {
                        console.info("BILLINGIS DELETED");
                    }
                    return;
                }

                const ROW = {
                    origem: "CV",
                    nif: BILLING.nif || "",
                    designacao: BILLING.invoice_partner_display_name,
                    tp_doc: "FT",
                    num_doc: "DA001",
                    data: THE_DATE,
                    vl_fatura: 0,
                    vl_base_incid: 0,
                    tx_iva: 0,
                    iva_sup: 0,
                    direito_ded: 0,
                    iva_ded: 0,
                    tipologia: "CD",
                    linha_dest_mod: 0
                }
                RowList.push(ROW);
            });


            const xmlReport = {
                header: {
                    ano: "2024",
                    mes: "12",
                    cd_af: "123",
                    nif:"0000",
                },
                linhas:RowList,
                dt_entrega: THE_DATE,
                total_fatura: 0,
                total_base_incid: 0,
                total_suportado: 0,
                total_dedutivel: 0,
                docname: "DOCNAME",
                groupname: "GROUPNAME",
                user_id: "USER_ID",
                date: THE_DATE,
            }

            this.xmlReport=xmlReport;
        }, 120);

        // <app-dpr-106>
        // </app-dpr-106>

    }

    confirmModel106() {
    }

    genModel106() {
    }

    FilterInvoices(filterx:any) {
        if (this.is_loading) {
            return;
        }

        const payload = {
            'operation' : 'QueryInvoices',
            'filterx' : filterx
        }

        this.is_loading = true;
        this.is_timeout = false;
        this.is_error = false;
        this.is_ready = false;
        this.objectCache = null;
        this.is_taking_long=false;
        const MAX_WAIT = 9500;

        setTimeout(() => {
            if (this.is_loading) {
                console.info("======= ITS TAKING TOO LONG ======");
                this.is_taking_long = true;
            }
        }, MAX_WAIT/2);

        setTimeout(() => {
            if (this.is_loading) {
                console.info("======= ITS TIMEOUT TIME ======");
                this.is_loading = false;
                this.is_taking_long = false;
                this.is_error = true;
                this.is_timeout = true;
            }
        }, MAX_WAIT);

        this.chatService.ExecuteTask("napps/ODOO", payload, (doc_resp:any) => {
            const object_:any = doc_resp.object_ || {};

            setTimeout(()=>{
                if (this.is_timeout) {
                    this.is_timeout=false;
                    this.is_loading=false;
                    return;
                }

                this.is_loading = false;
                if (object_ && doc_resp.Status=="OK") {
                    const info:any = object_;
                    this.is_ready = true;
                    this.objectCache = {
                        invoices: {                        
                            'total_count' : info['length'],
                            'records' : info['records'],
                        }
                    }

                    console.info("IS_SET [X] ", this.objectCache)
                } else {
                    this.is_error=true;
                }
            }, 60);

        }, (error:any) => {
            this.is_error = true;
            this.is_loading = false;
        });
    }

    FetchInvoices(force:boolean) {
        if (this.is_loading && !force) {
            return;
        }

        this.is_timeout = false;
        this.is_taking_long=false;
        const MAX_WAIT = 9500;

        setTimeout(() => {
            if (this.is_loading) {
                console.info("======= ITS TAKING TOO LONG ======");
                this.is_taking_long = true;
            }
        }, MAX_WAIT/2);

        setTimeout(() => {
            if (this.is_loading) {
                console.info("======= ITS TIMEOUT TIME ======");
                this.is_loading = false;
                this.is_taking_long = false;
                this.is_error = true;
                this.is_timeout = true;
            }
        }, MAX_WAIT);

        const payload = {
            'operation' : 'QueryInvoices',
        }

        this.is_loading = true;
        this.is_error = false;
        this.is_ready = false;
        this.objectCache = null;

        this.chatService.ExecuteTask("napps/ODOO", payload, (doc_resp:any) => {
            const object_:any = doc_resp.object_ || {};

            setTimeout(()=>{
                this.is_loading = false;
                if (this.is_timeout) {
                    this.is_timeout=false;
                    return;
                }

                if (object_ && doc_resp.Status=="OK") {
                    const info:any = object_;
                    this.is_ready = true;
                    this.objectCache = {
                        invoices: {                        
                            'total_count' : info['length'],
                            'records' : info['records'],
                        }
                    }

                    console.info("IS_SET [X] ", this.objectCache)
                } else {
                    this.is_error=true;
                }
            }, 60);

        }, (error:any) => {
            this.is_error = true;
            this.is_loading = false;
        });
    }

    goBack() {
        this.router.navigate(['/luaxapps']);
    }
}

