import { Component } from '@angular/core';

@Component({
  selector: 'app-stock-table',
  templateUrl: './stock-table.component.html',
  styleUrls: ['./stock-table.component.scss']
})
export class StockTableComponent {
  width: number = 400;
  height: number = 200;
  colorScheme = 'vivid'; // Usando um esquema de cores predefinido
  
  items = [
    {
      title: 'S&P 500',
      value: '5,505.07',
      change: '-39.52 (-0.71%)',
      chartData: [
        { name: 'Jan', value: 30 },
        { name: 'Feb', value: 40 },
        { name: 'Mar', value: 50 }
      ]
    },
    // Adicione mais itens conforme necessário
  ];

  tickFormatting(value: string): string {
    return value.slice(0, 3);
  }
}

