import { FormControl } from '@angular/forms';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormArray } from '@angular/forms';

@Component({
    selector: 'app-create-bi-report',
    templateUrl: './fatura-form.component.html',
    styleUrls: ['./fatura-form.component.scss']
})
export class FaturaFormComponent implements OnInit {
    @Input() reportReqObj={
        "name" : "Meu Relatorio 912323",
        'type' : "DEFAULT",
        "params" : {
            "start_date" : "", 
            "end_date" : "", 
            "question" : "", 
            'context' : "BI_DEMO",
            // 'context_id' : "BI_DEMO_0009",
        },
        "files" : [
            {'fileid':'asdasd','realname':'asdasd','filename':'asdasd'},
        ],
        "groups" : [
            {'groupname': 'supplier_details'}
        ],
        "rules" : [
            {'prompt': 'Total should not exceed 10000 CVE', 'params':[{'a':12, 'vv':23}]}
        ],
        "comments" : `
             id:"FOR/2024/03/0006", 
            supplier:Empresa Nacional de Correios e Telegrafos de Angola Largo Fernandes Coelho da Cruz Luanda 0101 Angola - 666666,
            dataC:"13-03-2024",
            datac:"13-03-2024" 
        `
    }

    is_loading:boolean = false
    is_invalid:boolean = false
    is_error:boolean = false
    is_created:boolean = false
    created_id:any = null

    reportObjReq:any = {
        reportName: "",
        start_date: "",
        end_date: ""
    }

    constructor(
        private chatService: ChatService,
        private fb: FormBuilder,
        public translate: TranslateService,
        private router: Router,
    ) {
        // this.subscription = this.chatService.editMessage$.subscribe(
        //     ({ message, index }) => this.setEditInput(message, index) 
        // );
        // this.resendQuestion = this.resendQuestion.bind(this);
    }

    ngOnInit() {
    }

    ValidateForm(reqObj:any) {
        const name = reqObj.reportName;
        const start_date = reqObj.start_date;
        const end_date = reqObj.end_date;
        const errors = [];

        if (!name || name.trim().length==0){
            errors.push("Nome invalido");
        }

        if (!start_date || start_date.trim().length<10){
            errors.push("Data de inicio invalida");
        }

        if (!end_date || end_date.trim().length<10){
            errors.push("Data de fim invalida");
        }

        return errors;
    }

    createReport() {
        this.is_invalid=false;
        const formData = this.reportObjReq;
        const formErrs = this.ValidateForm(formData);

        if (formErrs.length==0) { // NO ERRORS
            // console.info("FormData: ", formData);
            // return;
            if (this.is_loading) {
                return false;
            }

            const Name = formData.reportName.trim();
            const Type = 'DEFAULT';

            if (Name.length==0 || Name.length>300) {                
                this.is_invalid=true;
                return false;
            }            

            const context="NO";
            const contextId="NO";
            const start_date = formData.start_date;
            const end_date = formData.end_date;

            const reportReqObj = {
                name: Name,
                type: Type,
                params: {start_date, end_date},
            }

            const payload:any = {
                'ops' : 'SetupReport',
                'object_' : reportReqObj,
            }

            this.is_loading=true;

            const name = "lua/BIServiceAPI";
            this.chatService.ExecuteTask(name, payload, (Resp:any) => {
                console.info("CreatedReport: ", Resp);
                setTimeout(() => {
                    this.is_loading=false;
                    if (Resp.Status=="OK" && Resp.Id && Resp.Id.length>0) {                
                        this.is_created=true;
                        this.created_id = Resp.Id;
                    } else {
                        this.is_error=true;
                    }
                }, 220);

            }, (error:any) => {
                setTimeout(()=>{
                    this.is_error=false;
                    this.is_loading=false;
                    console.log('Error:', error);
                }, 320);
            });

        } else {
            setTimeout(()=>{
                this.is_invalid=true;
            }, 120);
            return false;      
        }

        return true;
    }

    ngOnDestroy(): void {
    }
}
