import { Component } from '@angular/core';

@Component({
  selector: 'app-portfolio-table',
  templateUrl: './portfolio-table.component.html',
  styleUrls: ['./portfolio-table.component.scss']
})
export class PortfolioTableComponent {
  portfolioData = [
    { symbol: '^IXIC', lastPrice: '17,726.94', change: '-144.28', percentChange: '-0.81%' },
    { symbol: 'GPS', lastPrice: '22.66', change: '-0.17', percentChange: '-0.74%' },
    { symbol: 'BMY', lastPrice: '42.65', change: '+0.26', percentChange: '+0.63%' },
    { symbol: 'CRWD', lastPrice: '304.96', change: '-38.09', percentChange: '-11.10%' },
    { symbol: 'DELL', lastPrice: '125.81', change: '+0.47', percentChange: '+0.37%' },
    { symbol: 'HPE', lastPrice: '20.48', change: '-0.19', percentChange: '-0.92%' },
    { symbol: 'CPRT', lastPrice: '51.71', change: '+0.06', percentChange: '+0.12%' },
    { symbol: 'DIS', lastPrice: '95.75', change: '-1.04', percentChange: '-1.07%' },
    { symbol: 'ARM', lastPrice: '163.40', change: '+5.07', percentChange: '+3.20%' },
    { symbol: 'ALNY', lastPrice: '237.83', change: '+1.13', percentChange: '+0.48%' }
  ];
}
