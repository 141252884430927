import { Component, Input, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
    selector: 'app-native-dpr',
    templateUrl: './banner.component.html',
    styleUrls: ['./banner.component.scss']
})
export class BannerComponent implements OnInit {
    @Input() appVersion: any = '';
    @Input() appPage: any = '';

    is_loading:boolean = false
    is_error:boolean = false
    is_ready:boolean = false
    
    faqQuestions = [
        { 
            question: 'Como criar modelos 106 apartir de faturas no lua', 
            answer: 'Você pode enviar faturas para o Lua de duas maneiras: pelo chat, anexando o arquivo ou texto da fatura, ou por e-mail, enviando o arquivo para o endereço de e-mail designado do Lua.', 
            open: false,
        },
        { 
            question: 'Como validar o modelo', 
            answer: 'Você pode enviar faturas para o Lua de duas maneiras: pelo chat, anexando o arquivo ou texto da fatura, ou por e-mail, enviando o arquivo para o endereço de e-mail designado do Lua.', 
            open: false,
        },
        { 
            question: 'Como entregar o modelo (porton di nos ilha)', 
            answer: 'Você pode enviar faturas para o Lua de duas maneiras: pelo chat, anexando o arquivo ou texto da fatura, ou por e-mail, enviando o arquivo para o endereço de e-mail designado do Lua.', 
            open: false,
        },
        { 
            question: 'Documentacao Adicional', 
            answer: 'Você pode enviar faturas para o Lua de duas maneiras: pelo chat, anexando o arquivo ou texto da fatura, ou por e-mail, enviando o arquivo para o endereço de e-mail designado do Lua.', 
            open: false,
        },
    ]

    constructor(
        private router: Router,
        private chatService: ChatService
    ) { 
        // console.info("[home] chatService: ", chatService);
    }

    ngOnInit() {
        this.InitApp()
    }

    InitApp() {
        this.is_loading = true;
        setTimeout(()=>{
            this.is_loading = false;
            this.is_ready = true;
        }, 569);
    }

    goBack() {
        this.router.navigate(['/luaxapps']);
    }
}
