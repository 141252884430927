import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';

@Component({
  selector: 'app-pie-chart',
  templateUrl: './pie-chart.component.html',
  styleUrls: ['./pie-chart.component.scss']
})
export class PieChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'pie'
    },
    title: {
      text: 'Pie Chart Example'
    },
    series: [{
      type: 'pie', // Defina o tipo da série como 'pie' para um gráfico de pizza
      name: 'Categories',
      data: [{
        name: 'Category A',
        y: 50 // Exemplo de dado para o gráfico de pizza
      }, {
        name: 'Category B',
        y: 30 // Outro exemplo de dado
      }, {
        name: 'Category C',
        y: 20 // Mais um exemplo de dado
      }]
    }]
  };
}
