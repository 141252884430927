<div class="p-4 rounded-md mb-4"
     [ngClass]="{
       'bg-green-100 text-green-800': type === 'success',
       'bg-blue-100 text-blue-800': type === 'info',
       'bg-yellow-100 text-yellow-800': type === 'warning',
       'bg-red-100 text-red-800': type === 'error'
     }">
  <div class="flex items-center">
    <div class="w-6 h-6 mr-2">
      <svg *ngIf="type === 'success'" class="w-6 h-6 text-green-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"/>
      </svg>
      <svg *ngIf="type === 'info'" class="w-6 h-6 text-blue-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5z"/>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 14l9-5-9-5-9 5 9 5z"/>
      </svg>
      <svg *ngIf="type === 'warning'" class="w-6 h-6 text-yellow-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4v.01"/>
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 9v2m0 4v.01"/>
      </svg>
      <svg *ngIf="type === 'error'" class="w-6 h-6 text-red-600" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"/>
      </svg>
    </div>
    <p class="text-sm font-medium">{{ message }}</p>
  </div>
</div>
