import { Component, Input } from '@angular/core';
import { ChatService } from 'src/app/services/chat.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-recent-prompts',
  templateUrl: './recent-prompts.component.html',
  styleUrls: ['./recent-prompts.component.scss']
})
export class RecentPromptsComponent {
    @Input() prompts: any[] = [
        // { key: 'PROMPTS.invoiceDetails', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
        // { key: 'PROMPTS.paymentDetails', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
        // { key: 'PROMPTS.bankDetails', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
        // { key: 'PROMPTS.productList', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    ];

    @Input() prompts_v2: any[] = [
        // { key: 'PROMPTS_V2.sendInvoice', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
        // { key: 'PROMPTS_V2.sendProduct', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
        // { key: 'PROMPTS_V2.odooIntegration', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
        // { key: 'PROMPTS_V2.model', url: '/assistant/chat', title: 'Learn', img: 'assets/img/icon/assistantCircuit.svg' },
    ];

    currentIndex: number = 0;

    constructor(
        private chatService: ChatService,
        public translate: TranslateService
    ) {}

    changeIndex(newIndex: number) {
        this.currentIndex = newIndex;
    }

    onSend(promptObj:any) {
        // a promptObj may contain a prompt key which will superseed the instruction to be sent
        // in case the prompt cannot be found, the display will be used
        // in case the display title will be used
        // in case the title cannot be found error should be reported
        var instruction='';
        if (promptObj.hasOwnProperty("prompt")) {
            instruction = promptObj['prompt'];
        } 
        else if (promptObj.hasOwnProperty("display")) {
            instruction = promptObj['display'];
        }
        else if (promptObj.hasOwnProperty("title")) {
            instruction = promptObj['title'];
        }

        if (instruction && instruction.trim().length>0) {
            if (this.chatService.loading) {
                return;
            }
            this.chatService.onSend(instruction);
            // am not sude what these codes do...
            const inputElement = document.querySelector('.input-container input');
            if (inputElement) {
                (inputElement as HTMLInputElement).value = '';
            }
        }

        // if (promptKey?.trim()) {
        //     const translatedPrompt = this.translate.instant(promptKey);
        //     if (translatedPrompt.trim()) {
        //         if (this.chatService.loading) {
        //           return;
        //         }

        //         // Enviar diretamente o prompt traduzido para o serviço de chat
        //         this.chatService.onSend(translatedPrompt);
        //         const inputElement = document.querySelector('.input-container input');
        //         if (inputElement) {
        //             (inputElement as HTMLInputElement).value = '';
        //         }
        //     }
        // }
    }


}
