import { HttpClient } from '@angular/common/http';
import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { BehaviorSubject, Subject } from 'rxjs';
import { ViewportScroller } from '@angular/common';
@Injectable({
    providedIn: 'root',
})
export class ChatService {
    samples: any[] = [];
    data: any[] = [];
    loading: boolean = false;
    private subject = new Subject<any>();
    private respIdSubject = new BehaviorSubject<string>(''); // Inicializa com valor vazio
    respId$ = this.respIdSubject.asObservable();
    private processing: boolean = false;
    private editMessageSource = new Subject<{ message: string, index: number }>();
    editMessage$ = this.editMessageSource.asObservable();
    private readonly apiUrl = 'https://nosi-assist-api.sintaxy.com/api/v1/brain/execute';
    private getApiCredentials() {
        const buf: any = localStorage.getItem("AUTH_CREDS");
        if (buf != null) {
            return JSON.parse(buf);
        } else {
            return { key: '', secret: '' };
        }
    }
    sendData(message: any) {
        this.subject.next(message);
    }
    getData() {
        return this.subject.asObservable();
    }
    constructor(
        private http: HttpClient,
        private viewportScroller: ViewportScroller
    ) { }

    isProcessing(): boolean {
        return this.processing;
    }
    executeOdoo(payload: any) {
        const reqObj = {
            task: "OpenERP",
            payload: payload
        }
        const pk = this.getApiCredentials();

        const conf = {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }
        this.loading = true;
        return this.http.post<any>(this.apiUrl, reqObj, conf);
    }
    isLoggedIn(): boolean {
        return !!localStorage.getItem('AUTH_CREDS');
      }
    ExecuteTask(taskName:string, payload: any, resolve_h:any, error_h:any) {
        const reqObj = {
            task: taskName,
            payload: payload
        }
        const pk = this.getApiCredentials();

        const conf = {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }
        this.loading = true;
        return this.http.post<any>(this.apiUrl, reqObj, conf)
       .subscribe(async (resp) => {
            try {
                resolve_h(resp);
            } catch (exp) {
                console.error("FAILED TO RESOLVE: ", exp);
                error_h(exp);
            }
        }, (error) => {
            console.log('Error:', error);
            if (error_h) {
                error_h(error);
            }
        });
    }

    createInvoice(item: any) {
        const apin = 'create_invoice';
        this.loading = true;
        const reqObj: any = item['reqObj'];
        this.executeOdoo({
            'ops': apin,
            'reqObj': reqObj,
        }).subscribe(
            async (resp) => {
                var msg = `Fatura criada com sucesso.`;
                if (resp.Status == "OK" && resp.Id) {
                    msg = `
                        <p>Fatura criada com sucesso.</p>
                        <p class="text-underline">Clique <a href="${resp.Link}" target="__blank"> aqui para abrir </a></p>
                    `;

                } else {
                    msg = resp.Msg;
                }
                const NewMsg = {
                    content: {
                        text: msg
                    },
                    question: "",
                    docname: resp.docname,
                    context: 'odoo_invoice',
                    report: {},
                    type: 'html',
                    actions: [],
                }

                if (resp.actions && resp.actions.length > 0) {
                    NewMsg.actions = resp.actions;
                }

                this.samples.push(NewMsg);
                document.body.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                });

                this.loading = false;

            }, (error: any) => {
                console.log('Error:', error);
                this.loading = false;
            }
        );
    }
    createSupplier(item: any) {
        const apin = 'create_partner';
        this.loading = true;
        const reqObj: any = item['reqObj'];
        this.executeOdoo({
            'ops': item['ops'],
            'reqObj': reqObj,
        }).subscribe(
            async (resp) => {
                const NewMsg = {
                    content: {
                        text: `Parceiro ${reqObj['name']} criado com sucesso.`
                    },
                    question: "",
                    docname: resp.docname,
                    context: 'odoo_partner',
                    report: {},
                    type: 'text',
                    actions: [],
                }

                if (resp.actions && resp.actions.length > 0) {
                    NewMsg.actions = resp.actions;
                }

                this.samples.push(NewMsg);
                document.body.scrollIntoView({
                    behavior: 'smooth',
                    block: 'end',
                    inline: 'nearest',
                });

                this.loading = false;

            }, (error: any) => {
                console.log('Error:', error);
                this.loading = false;
            }
        );
    }

    OnboardUser(resolve_h:any, error_h:any=null) {
        // this.loading_onbd = true;
        const reqObj = {
            task: "core/LuaOnboard",
            payload: {
                // TODO: allow user to send:
                // preferences
                // bio
                // alerts
                // categories
                // app preferences
                // lang preference
            }
        }

        const pk = this.getApiCredentials();
        const conf = {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }

        this.http.post<any>(this.apiUrl, reqObj, conf)
        .subscribe(async (resp) => {
            try {
                resolve_h(resp);
            } catch (exp) {
                console.error("FAILED TO RESOLVE: ", exp);
                error_h(exp);
            }
        },
        (error) => {
            console.log('Error:', error);
            if (error_h) {
                error_h(error);
            }
        }
        );
    }

    onSend(prompt?: string) {
        if (this.processing) {
            return;
          }
      
        this.processing = true
        this.samples.push({
            type: 'you',
            content: [prompt],
            perfil: 'assets/img/temp/profile.svg',
        });
        const reqObj = {
            task: "GemmaAssistant",
            payload: {
                question: prompt,
            }
        }
        const pk = this.getApiCredentials();

        const conf = {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }
        this.loading = true;
        this.http.post<any>(this.apiUrl, reqObj, conf)
            .subscribe(
                async (resp) => {
                    const NewMsg = {
                        content: {
                            text: resp.answer
                        },
                        question: prompt,
                        context: resp.groupname || 'general_details',
                        docname: resp.docname,
                        odoo_invoice: resp.odoo_invoice,
                        report: resp.report,
                        xml_report: resp.xml_report,
                        respId: resp.id,
                        type: 'text',
                        actions: [],
                    }
                   
                   
                    if (resp.report && resp.report.bank_details && resp.report.bank_details.__accounts) {
                        resp.report.bank_details.list = []
                        const map: any = resp.report.bank_details.__accounts
                        const keys = Object.keys(map);
                        keys.forEach(function (K) {
                            resp.report.bank_details.list.push({
                                bank: map[K]['bank'].toUpperCase(),
                                nib: map[K]['nib'],
                                BankName: map[K]['BankName'],
                                NIB: map[K]['NIB'],
                            })
                        })
                    }
                  

                    if (resp.actions && resp.actions.length > 0) {
                        NewMsg.actions = resp.actions;
                    }
                    this.samples.push(NewMsg);

                    document.body.scrollIntoView({
                        behavior: 'smooth',
                        block: 'end',
                        inline: 'nearest',
                    });
                    this.updateRespId(resp.id);
                    this.loading = false;
                    this.processing = false; 
                },
                (error) => {
                    console.log('Error:', error);
                    this.loading = false;
                    this.processing = false; 
                }
            );
    }
    updateRespId(respId: string): void {
        this.respIdSubject.next(respId);
    }

    pushObj(obj: any) {
        this.samples.push(obj);
    }

    setData(data: any[]) {
        this.data = data;
    }

    onFileSelected(event: Event): void {
        const input = event.target as HTMLInputElement;
        if (input.files && input.files.length > 0) {
            this.loading = true;
            const file = input.files[0];
            this.uploadFile(file);
        }
    }
    uploadFile(file: File): void {
        const url = `https://nosi-assist-api.sintaxy.com/api/v1/docanalysis/upload`;
        const reqObj = {
            task: "GemmaAssistant",
            payload: {
                question: prompt,
            }
        }

        const pk = this.getApiCredentials();

        const conf = {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }

        const formData = new FormData();
        formData.append('file', file, file.name);

        const UploadIsFinished = (response: any) => {
            const NewMsg = {
                content: {
                    text: "Documento <strong>" + response.Meta.realname + "</strong> carregado com sucesso<br/>" +
                        "Ref: " + response.Meta.name + "<br/>" +
                        "<small class=\"text-secondary\" style=\"color:#ccc;font-size:xx-small!important;\">Use a refer&ecirc;ncia para pesquisar no documento</small>"
                },
                actions: [
                    // {label:"Treinar", prompt:"Treine o documento"}, 
                    { label: "Analisar", prompt: "Analisa o documento" },
                    // {label:"Materializar", prompt:"Analisa o documento"}, 
                ],
                type: 'text'
            }

            this.samples.push(NewMsg);

            document.body.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
                inline: 'nearest',
            });
        }

        this.loading = true;

        this.http.post(url, formData, conf).subscribe(
            (response: any) => {
                const reqObj = {
                    'filename': response.Meta.name,
                    'name' : response.Meta.name,
                    'id' : response.Meta.id,
                    'realname' : response.Meta.realname,
                    'created_by' : response.Meta.created_by,
                }

                if ("https://nosi-assist-api.sintaxy.com".startsWith("http://")) {
                    UploadIsFinished(response);
                    this.loading = false;
                } else {

                    this.Execute("FileSync", reqObj)
                        .subscribe(async (resp) => {
                            UploadIsFinished(response);
                            this.loading = false;
                        }, (error) => {
                            console.log('Error:', error);
                            this.loading = false;
                        });
                }
            },
            (error) => {
                this.loading = false;
                console.error('Upload failed:', error);
            }
        );
    }

    Execute(task: string, payload: any) {
        const reqObj = {
            task: task,
            payload: payload
        }

        const pk = this.getApiCredentials();

        const conf = {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }
        return this.http.post<any>(this.apiUrl, reqObj, conf);
    }
    updateVote(answerId: string, vote: string) {
        const payload = {
            id: answerId,
            vote: vote,
        };

        const task = 'Feedback';
        const pk = this.getApiCredentials();
        this.http.post(this.apiUrl, { task, payload }, {
            headers: {
                'api-key': pk.key,
                'api-secret': pk.secret,
            }
        }).subscribe(
            response => {
               
            },
            error => {
                console.error('Erro ao atualizar o voto', error);
            }
        );
    }
    triggerEditMessage(message: string, index: number) {
        this.editMessageSource.next({ message, index });
    }
    resetChatAndSendNewPrompt(index: number, newPrompt: string) {
        if (index >= 0 && index < this.samples.length) {
            this.samples = this.samples.slice(0, index);
            this.onSend(newPrompt);
        }
    }
    handleReload(index: number, prompt: string) {
        if (index >= 0 && index < this.samples.length) {
            // Remove items after the given index
            // this.samples = this.samples.slice(0, index);{coment}
            this.onSend(prompt);
        }
    }
    submitForm(formData: any): Observable<any> {
        const reqObj = {
          task: 'dpr/XMLProc', // Verifique se esta é a tarefa correta
          payload: formData
        };
    
        // Obtenha as credenciais da API (se necessário)
        const pk = this.getApiCredentials();
    
        const conf = {
          headers: {
            'api-key': pk.key,
            'api-secret': pk.secret,
          }
        };
    
        return this.http.post<any>(this.apiUrl, reqObj, conf);
      }
    

}
