import { Component, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-my-account',
  templateUrl: './my-account.component.html',
  styleUrls: ['./my-account.component.scss']
})
export class MyAccountComponent {
  @ViewChild('fileInput', { static: false }) fileInput!: ElementRef;

  triggerFileUpload() {
    if (this.fileInput) {
      this.fileInput.nativeElement.click();
    }
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    const file = input?.files ? input.files[0] : null;

    if (file) {
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const imgElement = document.querySelector('img[alt="Profile Picture"]') as HTMLImageElement;
        if (imgElement) {
          imgElement.src = e.target.result;
        }
      };
      reader.readAsDataURL(file);
    }
  }

  saveChanges() {
    // Função para salvar alterações
  }
}
