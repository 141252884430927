import { ViewportScroller } from '@angular/common';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TranslateService } from '@ngx-translate/core'; // Importar TranslateService
import { ChatService } from 'src/app/services/chat.service';

import { Router } from '@angular/router';
import { samples } from '../chat/sample';

@Component({
  selector: 'app-chat-mobile',
  templateUrl: './chat-mobile.component.html',
  styleUrls: ['./chat-mobile.component.scss']
})
export class ChatMobileComponent {
  currentPrompt: string = '';
  selectedTab: string = 'youCanAsk';
  is_loading:boolean = false
  is_error:boolean = false
  user_has_hidden:boolean = false
  onboard_prof:any = null
  onbd_prompts:any[] = []

  constructor(
      private http: HttpClient,
      private route: ActivatedRoute,
      private router: Router,
      private viewportScroller: ViewportScroller,
      private chatService: ChatService,
      private translate: TranslateService // Injetar TranslateService
  ) {
  }

  prompts: any[] = [];
  prompts_v2: any[] = [];

  ngOnInit() {
      const data = this.chatService.getData();
      data.subscribe((res) => {
          if (res) {
              this.onSend(res);
          } else {
          }
      });

      const spls = this.getSamples();
      console.info("spls: ", spls);
      if (!spls || spls.length==0) {
          if (this.onboard_prof==null) {
              this.ExecuteOnboard()
          }
      }
  }

  goWithHiddingGuide() {
      this.onboard_prof=null;
      this.user_has_hidden=true;
  }

  refreshONB() {
      // console.info("refreshONB: ");
      this.ExecuteOnboard();
  }

  uploadFile(event:any) {
      // console.info("uploadFile: ", event);
      this.chatService.onFileSelected(event)
      // this.router.navigate(['/chat']);
  }

  openHelpGuide() {
      this.router.navigate(['/guide']);        
  }

  openMode(mode:any) {
      // console.info("OPEN-MODE: ", mode)
  }

  openDoc(doc:any,i:any) {
      // console.info("openDoc: ", doc, i);
      var tms = doc['tms'];
      var realname = doc['realname'];
      var filename = doc['filename'];
      var docDetailsPrompt = "Detalhes do documento "+filename;

      this.chatService.onSend(docDetailsPrompt);
      setTimeout(()=>{
          this.router.navigate(['chat']);
      }, 120);
  }

  openMemConversation(memory_item: any) {
      // console.info('openMemConversation: ', memory_item);
      const prompt = memory_item['prompt']
      this.chatService.onSend(prompt);

      setTimeout(()=>{
          this.router.navigate(['chat']);
      }, 120);
  }

  ExecuteOnboard() {
      console.info("[chat.component] ExecuteOnboard")
      if (this.is_loading) {
          return;
      }

      this.is_loading = true
      this.is_error = false
      this.onboard_prof = null;

      this.chatService.OnboardUser((onboard_resp:any) => {
          setTimeout(() => {
              // console.info("[chat.component] onboard_resp: ", onboard_resp)
              // if (!onboard_resp) {
              //     console.warning("RETURNED EMPTY PROFILE: ", onboard_resp)
              // }
              const onbd_prompts = onboard_resp['prompts'] 
              this.onbd_prompts = onbd_prompts;

              const stm_stream = onboard_resp['stm_stream'];
              var stm_stream_l:any[] = [];

              if (stm_stream) {
                  const keys = Object.keys(stm_stream);
                  stm_stream_l = keys.map(function(K){
                      const obj = stm_stream[K];
                      return {
                          'key':'', 
                          'url': '/assistant/chat', 
                          'title':  obj['q'],
                          'prompt' : obj['q'], 
                          'answer' : obj['a'], 
                          'tms' : K,
                          'id' : obj['Id'],
                          'img': 'assets/img/icon/assistantCircuit.svg' 
                      }
                  })
              }

              // console.info("stm_stream_l: ", stm_stream_l);

              this.onboard_prof = {
                  'title' : onboard_resp['answer'],
                  'answer_details' : onboard_resp['answer_details'],
                  'history' : onboard_resp['history'],
                  'stm_stream' : stm_stream_l,
                  // 'stm_stream' : onboard_resp['stm_stream'],
                  'preferences' : onboard_resp['preferences'],
                  'recent_docs' : onboard_resp['recent_docs'],
                  'profile' : onboard_resp['profile'],
              }
              this.is_loading = false;
          }, 150);

      }, (error:any) => {
          console.error("error: ", error)
          this.is_loading = false
          this.is_error = true
      });
  }


  fetchData(p: any, headers: HttpHeaders) {
      this.chatService.setData(samples);
      // samples.forEach((item, i) => {});
      if (p.q) {
          this.onSend(p.q);
      } else { 
          this.chatService.loading = false;
      }
  }

  onSend(promptKey?: string) {
      if (promptKey?.trim()) {
          const translatedPrompt = this.translate.instant(promptKey);
          if (translatedPrompt.trim()) {
              if (this.chatService.loading) {
                  return;
              }
              // Enviar diretamente o prompt traduzido para o serviço de chat
              this.chatService.onSend(translatedPrompt);
              const inputElement = document.querySelector('.input-container input');
              if (inputElement) {
                  (inputElement as HTMLInputElement).value = '';
              }
          }
      }
  }

  scrollToBottom() {
  }

  getSamples() {
      return this.chatService.samples;
  }
  
}
