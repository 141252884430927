<app-mobile-menu [dark]="true" [sideMenu]="sideMenu" *ngIf="url!=='mobile-app'"></app-mobile-menu>

<div class="bg-white overflow-hidden flex justify-center min-h-screen">
  <!-- Top Bar with Hamburger Icon -->
  <div class="fixed top-0 left-28 right-0 flex items-center justify-between h-[70px] bg-white z-20 px-4 md:px-5">

    <div class="flex-1 p-4">
      <img src="assets/img/logoNosi.svg" alt="Logo">
    </div>
    <div  class="flex flex-row" *ngIf="url === '/' || url === 'home' " >
      <div class="inset-y-0 right-5  pointer-events-none rounded-full">
        <img [src]="getFlag(selectedLanguage)" alt="Selected Language Flag" class="w-10 h-10 rounded-full"/>
      </div>
      <select class="text-black py-2" (change)="onLanguageChange($event)" [(ngModel)]="selectedLanguage">
        <option *ngFor="let lang of languages" [value]="lang.code">{{ lang.name }}</option>
      </select>
    </div>
    <div class="pr-20" *ngIf="url === 'chat'">
      <img class="rounded-full w-14 h-14 border-2 border-blue-300" src="assets/img/im blue.jpg" alt="Logo">
    </div>
  </div>
  <!-- Side Menu -->
  <div
    class="fixed top-0 left-0 w-[100px] h-screen overflow-y-auto bg-[#F5F5F5] md:flex flex-col pt-7 px-7 text-black z-18"
    [ngClass]="{'hidden': !menuOpen, 'block': menuOpen}">
    <ng-container *ngFor="let item of sideMenu; let i = index">
      <div class="hover:scale-105 active:scale-90 transform ease-in-out flex flex-col items-center  cursor-pointer mb-6"
        [routerLink]="item.route">
        <img *ngIf="item.route !== '/' + url" [src]="item.baseIcon" alt="" />
        <img *ngIf="item.route === '/' + url" [src]="item.activeIcon" alt="" />
        <div [ngClass]="{'text-[#004E7E]': item.route === '/' + url, 'text-[#000]': item.route !== '/' + url}"
          class="text-[14px] font-normal">
          {{ item.title }}
        </div>
      </div>
    </ng-container>
    <div class="mt-auto flex flex-col items-center gap-4 cursor-pointer mb-5">
      <button class="text-sm " (click)="logout()">
        <img src="assets/img/logout.svg" alt="Logout">
        Logout</button>
    </div>
  </div>
  <!-- Main Content -->
  <div class="w-full relative md:ml-[150px]">
    <div
      class="mt-[70px] relative md:mt-[10px] md:pl-[72px] md:pt-[88px] md:pr-[72px] p-5 w-full max-w-screen-2xl mx-auto bg-white text-black">
      <ng-content></ng-content>
    </div>
    <div *ngIf="url=='chat'||url=='home' || url=='mobile-app'"
      class="fixed bottom-0 w-full md:w-[90%] px-16 md:px-[200px] bg-white flex items-center z-50 pb-8 inputchat">
      <div class="px-2 md:px-0 w-full">
        <app-input-chat class="w-full" [chatData]="{classContainer: '', classInput: '!h-[3rem] w-full'}"
          [(inputValue)]="currentPrompt" (btnClick)="onSend(currentPrompt)"></app-input-chat>
      </div>
    </div>
  </div>
</div>