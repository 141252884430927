<nav class="bg-white shadow-md">
  <div class="container mx-auto flex items-center justify-between p-4">
    <a class="flex items-center" href="#">
      <img src="assets/logo.png" alt="Logo" class="h-10">
    </a>
    <ul class="flex items-center space-x-4">
      <li class="relative">
        <mat-form-field class="w-full">
          <mat-icon matPrefix>
            <img src="assets/img/icon/search.svg" alt="Search Icon" class="h-5 w-5">
          </mat-icon>
          <input type="text" matInput [formControl]="searchControl" [matAutocomplete]="auto" placeholder="Search" class="p-2 border rounded-md">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
              {{option}}
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </li>
      <li>
        <mat-icon class="text-gray-600">notifications</mat-icon>
      </li>
      <li>
        <a class="text-gray-800 hover:text-blue-500" href="#">Home</a>
      </li>
      <li>
        <a class="text-gray-800 hover:text-blue-500" href="#">Finance</a>
      </li>
      <!-- User Icon with Dropdown -->
      <li class="relative">
        <button mat-icon-button  class="flex items-center">
          <!-- Exibe a foto do usuário ou um ícone padrão se a foto não estiver disponível -->
          <img *ngIf="userPhoto" [src]="userPhoto" alt="User Photo" class="h-10 w-10 rounded-full object-cover">
          <mat-icon *ngIf="!userPhoto" class="h-10 w-10">account_circle</mat-icon>
        </button>
      </li>
      <!-- Dropdown Button -->
      <li class="relative flex items-center">
        <button mat-button [matMenuTriggerFor]="dropdownMenu" class="text-gray-800 hover:text-blue-500">Drop<mat-icon class="ml-2">keyboard_arrow_down</mat-icon></button>
        <mat-menu #dropdownMenu="matMenu">
          <button mat-menu-item>Minha Conta</button>
          <button mat-menu-item>Logout</button>
          <button mat-menu-item>Settings</button>
        </mat-menu>
      </li>
    </ul>
  </div>
</nav>
