<div class="accordion">
    <div *ngFor="let panel of panels; let i = index" class="panel">
      <div class="panel-header" (click)="togglePanel(i)">
        <div class="flex justify-between items-center">
          <span class="text-lg font-medium">{{ panel.title }}</span>
          <svg class="w-4 h-4 transition-transform transform"
               [ngClass]="{'rotate-0': activePanelIndex !== i, 'rotate-180': activePanelIndex === i}"
               xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
            <path fill-rule="evenodd"
                  d="M10 3a1 1 0 0 1 1 1v12a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1zm0-2a3 3 0 0 0-3 3v12a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"/>
          </svg>
        </div>
      </div>
      <div *ngIf="activePanelIndex === i" class="panel-content">
        <p class="text-gray-700">{{ panel.content }}</p>
      </div>
    </div>
  </div>
  