import { Component } from '@angular/core';

@Component({
  selector: 'app-card-list',
  templateUrl: './card-list.component.html',
  styleUrls: ['./card-list.component.scss']
})
export class CardListComponent {
  cards = [
    {
      imageUrl: 'https://via.placeholder.com/150',
      title: 'Card 1',
      description: 'Description for card 1.',
      buttons: [
        { text: 'Button 1', action: this.action1 },
        { text: 'Button 2', action: this.action2 }
      ]
    },
    {
      imageUrl: 'https://via.placeholder.com/150',
      title: 'Card 2',
      description: 'Description for card 2.',
      buttons: [
        { text: 'Button 1', action: this.action1 },
        { text: 'Button 2', action: this.action2 }
      ]
    },
    {
      imageUrl: 'https://via.placeholder.com/150',
      title: 'Card 3',
      description: 'Description for card 3.',
      buttons: [
        { text: 'Button 1', action: this.action1 },
        { text: 'Button 2', action: this.action2 }
      ]
    }
  ];

  action1(): void {
    // Define your action for Button 1 here
    console.log('Action 1 clicked');
  }

  action2(): void {
    // Define your action for Button 2 here
    console.log('Action 2 clicked');
  }
}
