<app-user-layout style="background-color:#f4f4f4;">

    <div class="mt-">   
        <h3 class="text-2xl font-extrabold text-left text-[#1422a9]"> 
            Lua MCC
        </h3>
        <p class="text-left text-[small] opacity-55"> 
            Lua's MCC (Memory Circuit Console) allows you to peek
            inside Lua's main memory and follow hear learning path continously.
            <!-- inside Lua's main memory and see how wonderfully fast she learns. -->
        </p>
        <hr />
        <!--
        <button class="border p-3 rounded-full bg-[#5BACAD] text-white border-[#5BACAD] flex items-center">
            Voltar
        </button>
        -->
        <div class="mt-4">
            <app-alert type="warning" message="Warning! Anxiety, and other Psycologically induced  effects are possible, proceed with caution">
            </app-alert>
        </div>
    </div>

    <div class="grid grid-cols-2 mt-4"
        style=""
        *ngIf="stmcg_obj && stmcg_obj.Status=='OK'">
        <div class="grid-col " 
            *ngFor="let panel of stmcg_obj.memgroup_list; let i = index">
            <div class="accordion mb-1 mr-1"
                style="padding:0px;">
                <div class="panel">
                    <div class="panel-header">
                        <div class="flex justify-between items-center"
                              style="background-color:#152272;/*#0a2aeb,#152272*/">
                            <span class="text-lg text-white font-medium" 
                                style="padding-left: 9px;">
                                {{ panel.title }}                                
                            </span>
                            <span class="text-sm text-white " 
                                style="padding-right: 9px;font-size: xx-small;">
                                {{ "(" + panel.llm_model + ")" }}                                
                            </span>
                        </div>
                    </div>

                    <div class="panel-content"
                        style="height:300px;max-height: 350px;overflow: hidden;background-color:#01051e">
                        <!-- COUNTERS -->
                        <div class="grid grid-cols-2 mt-0">
                            <div class="grid-col">
                            </div>

                            <div class="grid-col">
                                <div class=" text-right pr-10">
                                    <h3 class="text-white mb-0 mt-0 display-inline">Q&A</h3>
                                    <div class="text-lg font-bold text-white mb-0 mt-0 display-inline">15K</div>
                                    <div class="text-red-600 mb-0 mt-0">-4%</div>
                                </div>
                            </div>

                            <div class="grid-col">
                                <div class=" text-right pr-10">
                                    <h3 class="text-blue-600 mb-0 mt-0 display-inline">L.rate</h3>
                                    <div class="text-lg text-white font-bold mb-0 mt-0 display-inline">9.8r</div>
                                    <div class="text-green-600 mb-0 mt-0">4%</div>
                                </div>
                            </div>
                        </div>


                        <div style="min-height:10px;">
                            <table class="w-full border-collapse"
                                style="margin:0px;">
                                <!--
                                <thead>
                                    <tr class="bg-gray-100">
                                        <th class="px-4 py-2 border-b">
                                            <p class="text-gray-700 pl mt-0 mb-0">
                                                <span class="text-sm text-white " 
                                                      style="">
                                                    {{ panel.context }}#{{ panel.context_id}}
                                                </span>
                                            </p>
                                        </th>
                                        <th class="px-4 py-2 border-b">%rel.</th>
                                    </tr>
                                </thead>
                                -->
                                <tbody>
                                    <tr *ngFor="let item of panel.streamData; let i = index">
                                        <td class=" border-b text-blue-500"
                                            style="color:#fff">
                                            {{ item.symbol }}
                                        </td>
                                        
                                        <td class=" py-2 border-b">
                                            <p class="text-white">
                                                {{ item.lastPrice }}
                                            </p>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
  
            <!-- <app-accordion></app-accordion> -->
            <!-- <app-bar-chart></app-bar-chart> -->
        </div>

        <div class="grid-col">
            <!-- <app-alert type="info" message="Heads up!"></app-alert> -->
            <!-- <app-accordion></app-accordion> -->
        </div>
    </div>

<!--     
    <div class="flex flex-wrap gap-52">
        <div class="w-full sm:w-1/2 md:w-1/3 mb-4">
            <app-bar-chart></app-bar-chart>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 mb-4">
            <app-pie-chart></app-pie-chart>
        </div>
        <div class="w-full sm:w-1/2 md:w-1/3 mb-4">
            <app-line-chart></app-line-chart>
        </div>
        <app-calender></app-calender>        
    </div>
    <app-tab-component></app-tab-component>
-->    

    <!-- 
    <app-counter></app-counter>
     <app-accordion></app-accordion>
    <app-card-list></app-card-list>
    <app-crowd-strike-outage>
    </app-crowd-strike-outage>

    <app-options-table>
    </app-options-table>

    <app-portfolio-table>
    </app-portfolio-table>

    <app-stock-table>
    </app-stock-table>

    <app-alert type="success" message="Well done!"></app-alert>
    <app-alert type="info" message="Heads up!"></app-alert>
    <app-alert type="warning" message="Warning!"></app-alert>
    <app-alert type="error" message="Oh snap!"></app-alert>
    -->

</app-user-layout>