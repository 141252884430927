<div class="space-x-4 overflow-x-auto p-4 flex flex-col">
  <div class="flex flex-col items-center">
    <div *ngFor="let item of items" class="flex flex-col items-center space-y-2">
      <h3 class="text-blue-600">{{ item.title }}</h3>
      <div class="text-lg font-bold">{{ item.value }}</div>
      <div class="text-red-600">{{ item.change }}</div>
      <div class="w-full" style="height: 300px;">
      <!--  <ngx-charts-line-chart
        [view]="[width, height]"
        [scheme]="colorScheme"
        [results]="item.chartData"
        [xAxis]="true"
        [yAxis]="true"
        [legend]="false"
        [showXAxisLabel]="true"
        [showYAxisLabel]="true"
        [xAxisLabel]="'Month'"
        [yAxisLabel]="'Value'"
        [autoScale]="true"
        [xAxisTickFormatting]="tickFormatting"
      >
      </ngx-charts-line-chart>-->
      </div>
    </div>
  </div>
</div>
