<app-user-layout>

<div class="flex flex-col md:flex-row pt-10 pb-50">
    <!--
    Documentos -> /documents [O]
    Dashboard -> /dashboard [O]
    Guia & Ajuda-> /guide [CRIT]
    Relatorios -> /reports [CRIT]
    Lua Apps -> /luaxapps [IDEAL]
             -> /guide 
             -> /dashboard
             -> /help
             -> /trainsystem
             -> /reports
            -> /aibi
    -->
    <div class="hidden md:block w-full md:w-80 lg:w-auto md:-ml-[125px] ">
        <img src="assets/img/Banner.svg" alt="Logo" class="h-[550px] lg:-mt-7 md:mt-28" />
    </div>

    <div class="w-full md:w-[85%] grid grid-cols-1 place-content-center text-left text-[#414141] ">

        <div class="grid-col">

            <div>
                <img src="assets/img/im blue.jpg" alt="Logo" 
                class="rounded-full w-10 h-10 border-2 pull-left border-blue-300" />
                <h1 class="text-4xl text-left mb-4">
                    Lua Apps
                </h1>
            </div>

            <p class="m-0 opacity-70">
                Apps para analise, integracao, producao, consumo e verificacao de relatorios,
            </p>
            <p class="m-0 opacity-70">
                Suporte especial para ODOO, Prodoc, Mattermost, EmailInbox, DPR.
            </p>
        </div>

        <div class="grid-col">
            <div class="mt-5 mb-5  flex flex-row gap-2">
                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span class="cursor-pointer text-[small] p-2 bg-[#EAF2F2] text-[#00527E] rounded-xl m-2 border border-[#00527E]"
                        (click)="openHelpGuide()">
                        Guia &amp; Ajuda
                    </span>
                </div>
                <!-- <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 ">
                    <img src="assets/img/icon/Demo.svg" alt="Lua" />
                    <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]"
                        (click)="openMode('demo')">
                        Ver Demo
                    </span>
                </div>-->
                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 " (click)="openPage('/documents')">
                    <img src="assets/img/icon/notehg.svg" alt="Lua" />
                    <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]">
                        Documentos
                    </span>
                </div>
                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 " (click)="openPage('/reports')">
                    <img src="assets/img/icon/setting.svg" alt="Lua" />
                    <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]">
                        Relat&oacute;rios
                    </span>
                </div>
                <!--
                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 ">
                    <img src="assets/img/icon/setting.svg" alt="Lua" />
                    <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]">
                        Lua AI
                    </span>
                </div>
                -->
            </div>

            <div class="mt-5 mb-5  flex flex-row gap-2">
                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36"
                    (click)="AV_SOON('ANALISE FINANCEIRA')">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span class="cursor-pointer p-2 bg-[#EAF2F2] text-[#00527E] rounded-xl m-2 border border-[#00527E]" style="font-size:x-small;">
                        ANALISE FINANCEIRA
                    </span>
                </div>

                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36"
                    (click)="AV_SOON('GERAR RELATORIOS')">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span class="cursor-pointer p-2 bg-[#EAF2F2] text-[#00527E] rounded-xl m-2 border border-[#00527E]"
                        style="font-size:x-small;">
                        GERAR RELATORIOS
                    </span>
                </div>

                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36"
                    (click)="OpenApp('ODOO')">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span class="cursor-pointer p-2 bg-[#EAF2F2] text-[#00527E] rounded-xl m-2 border border-[#00527E]"
                        style="font-size:x-small;">
                        OPENERP - ODOO
                    </span>
                </div>

                <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36"
                    (click)="OpenApp('DPR_106')">
                    <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                    <span class="cursor-pointer p-2 bg-[#EAF2F2] text-[#00527E] rounded-xl m-2 border border-[#00527E]"
                        style="font-size:x-small;">
                        DPR - MODELO 106
                    </span>
                </div>
            </div>
        </div>

        <div class="grid-col">
            <div>
                <h1 class="text-4xl text-left mb-4">
                    Como funciona
                </h1>
            </div>

            <div>
                <app-faq></app-faq>         
            </div>
        </div>


    </div>

</div>


</app-user-layout>
