import { Component } from '@angular/core';

@Component({
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: ['./accordion.component.scss']
})
export class AccordionComponent {
  panels = [
    { title: 'Panel 1', content: 'Content of panel 1' },
    { title: 'Panel 2', content: 'Content of panel 2' },
    { title: 'Panel 3', content: 'Content of panel 3' }
  ];
  activePanelIndex = -1;

  togglePanel(index: number): void {
    if (this.activePanelIndex === index) {
      this.activePanelIndex = -1; // Fechar painel se já estiver aberto
    } else {
      this.activePanelIndex = index;
    }
  }
}
