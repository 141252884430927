<!-- banner.component.html -->
<div class="bg-black h-[500px] flex ">
  <div class="text-center">
    <h1
      class="text-6xl mb-5 mt-3 text-center  pl-2 text-white">
      Upgrade from Java 8 to Java 17
    </h1>
    <div class="grid grid-cols-2 ">
      <div class="mt-44 ">
        <p
          class="text-7xl font-bold mb-2.5 bg-clip-text text-transparent bg-gradient-to-r from-purple-700 via-purple-500 to-purple-300">
          1,000+
        </p>
        <p class="text-sm mb-2.5 bg-clip-text text-white"> Upgraded production applications</p>
      </div>
      <div class="mt-10 text-justify ">
        <p
          class="text-lg mb-2.5 bg-clip-text text-transparent bg-gradient-to-r from-purple-700 via-purple-500 to-purple-300">
          10 minutes
        </p>
        <p class="text-sm mb-2.5 bg-clip-text text-white">

          Average per application
        </p>
        <hr />
        <p
          class="text-lg mb-2.5 bg-clip-text text-transparent bg-gradient-to-r from-purple-700 via-purple-500 to-purple-300">
          2 days</p>

        <a href="#" class="bg-black text-white py-2 px-5 border-none cursor-pointer hover:bg-white hover:text-black">
          Upgrade Now
        </a>
      </div>
    </div>
  </div>
</div>