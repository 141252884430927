<div class="">
    <div class="header">
        <!-- <img src="logo.png" alt="Logo" class="logo"> -->
        <div class="invoice-info" *ngIf="report.general_details">
            <p class="invoice-title">Fatura de Fornecedor</p>
            <!-- <p class="invoice-number">FOR /2024/03/0006</p> -->
            <p class="invoice-number">FOR {{ report.general_details.id}}#{{ report.general_details.ref}}</p>
        </div>
    </div>

    <div class="invoice-details">
        <div class="flex ">
            <div class=" mb-2 ">
                <div *ngIf="report.supplier_details">
                    <p class="mb-2"><b>Fornecedor:</b> {{ report.supplier_details.name }}</p>
                    <p class="mb-2"><b>NIF:</b> {{ report.supplier_details.nif||report.supplier_details['taxid/nif'] }}
                    </p>
                    <p class="mb-2"><b>Contacto:</b>
                        {{ report.supplier_details.phonenumber }},
                        {{ report.supplier_details.website }},
                    </p>
                    <p class="mb-2"><b>Endere&ccedil;o:</b>
                        {{ report.supplier_details.address }},
                        {{ report.supplier_details.zipcode }} 
                    </p>
                    <p class="mb-2" *ngIf="report.general_details">
                        <b>Data Contabilistica:</b> {{ report.general_details.date}}
                    </p>
                    <p class="mb-2" *ngIf="report.general_details">
                        <b>Data Vencimento:</b> {{ report.general_details.data_vencimento }}
                    </p>
                </div>
            </div>
            <div class=" mb-5  ml-14">
                <div *ngIf="report.customer_details">
                    <p class="mb-2"><b>Cliente:</b> {{ report.customer_details.name }}</p>
                    <p class="mb-2"><b>NIF:</b> {{
                        report.customer_details.NIF||report.customer_details.nif||report.customer_details['taxid/nif']
                        }}</p>
                    <p class="mb-2"><b>Contacto:</b>
                        {{ report.customer_details.phonenumber }},
                        {{ report.customer_details.website }},
                    </p>
                    <p class="mb-2"><b>Endere&ccedil;o:</b>
                        {{ report.customer_details.address }},
                        {{ report.customer_details.zipcode }}
                    </p>
                </div>
            </div>
        </div>
    </div>

    <div class="overflow-x-auto  ">
        <div>
            <table class="  sm:w-[100%] table mb-10 md:w-[80%] " *ngIf="report.product_list">
                <thead>
                    <tr>
                        <th>Ref</th>
                        <th>Descrição</th>
                        <th>Quantidade</th>
                        <th>Preço</th>
                        <th>Impostos</th>
                        <th>Subtotal</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let prod of report.product_list.list">
                        <td class="text-center"> {{ prod['id/lote'] ? prod['id/lote'] : prod.id + '#' + prod.lote }}
                        </td>
                        <td class="text-center">
                            {{ prod.design ? prod.design : (prod.description ? prod.description :
                            prod['designa&ccedil;&atilde;o']) }}
                        </td>
                        <td class="text-center"> {{ prod.qtd }} {{prod.unit||'UN'}}</td>
                        <td class="text-center">{{ prod['price/unit'] ? prod['price/unit'] : prod.price }}</td>
                        <td class="text-center">{{ prod.iva }}</td>
                        <td class="text-center">{{ prod.qtd * prod.price}}</td>
                    </tr>
                    <tr *ngIf="report.total_without_tax">
                        <th class="text-left" colspan="5">Subtotal S/IVA</th>
                        <td class="text-center">{{ report.total_without_tax.amount }}</td>
                    </tr>
                    <tr *ngIf="report.total_with_tax">
                        <th class="text-left" colspan="5">Total C/IVA</th>
                        <td class="text-center">{{ report.total_with_tax.amount }}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div *ngIf="report.payment_details">
        <p><b>Pagamento:</b> </p>
        <div class="items-center flex justify-center">
            <table class="table mb-8 md:w-[95%]  sm:w-[100%]">
                <tbody>
                    <tr>
                        <td> Métodos de pagamento </td>
                        <td>
                            {{ report.payment_details?.method ?? 
                               report.payment_details?.['metodo de pagamento'] ?? 
                               report.payment_details?.metododepagamento }}
                          </td>
                    </tr>
                    <tr>
                        <td> Entidade </td>
                        <td> {{ report.payment_details.entity || report.payment_details.entidade }} </td>
                    </tr>
                    <tr>
                        <td> Referência </td>
                        <td> {{
                            report.payment_details['referência'] ||
                            report.payment_details['referencia'] ||
                            report.payment_details['reference']
                            }}
                        </td>
                    </tr>
                    <tr>
                        <td> Subtotal </td>
                        <td> {{ report.payment_details.subtotal }} </td>
                    </tr>
                    <tr>
                        <td> Total </td>
                        <td> {{ report.payment_details.total }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>
    
    <div *ngIf="report.bank_details && report.bank_details.list">
        <p class="mb-3 m"><b>Detalhes Banc&aacute;rios</b></p>
        <div class="items-center flex justify-center">
            <table class=" table  md:w-[70%] sm:w-[100%]">
                <thead>
                    <tr>
                        <th class="text-center">Banco</th>
                        <th>Conta/NIB</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let acc of report.bank_details.list">
                        <td class="text-center">{{ acc.bank ? acc.bank : acc.BankName }}</td>
                        <td class="text-center"> {{ acc.nib ? acc.nib : acc.NIB }} </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </div>

</div>