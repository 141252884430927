<div class="faq-container flex flex-col md:flex-row">
  <!-- FAQ Left Column -->
  <div class="faq-column w-full md:w-1/2 p-4 overflow-y-auto">
    <div class="faq-row" *ngFor="let item of faqsLeft">
      <div class="faq-item border border-gray-300 rounded-xl mb-2">
        <div class="faq-question flex items-center justify-between p-4 cursor-pointer" (click)="toggleAnswer(item)">
          <span class="text-sm font-semibold">{{ item.question }}</span>
          <ng-container *ngIf="!item.open">
            <img src="assets/img/icon/arrow-down.svg" class="h-6 w-6" />
          </ng-container>
          <ng-container *ngIf="item.open">
            <img src="assets/img/icon/arrow-up.svg" class="h-6 w-6" />
          </ng-container>
        </div>
        <div *ngIf="item.open" class="faq-answer text-sm p-4 text-justify bg-white border-t border-gray-300">
          <p>{{ item.answer }}</p>
        </div>
      </div>
    </div>
  </div>

  <!-- FAQ Right Column -->
  <div class="faq-column w-full md:w-1/2 p-4 overflow-y-auto">
    <div class="faq-row" *ngFor="let item of faqsRight">
      <div class="faq-item border border-gray-300 rounded-xl mb-2">
        <div class="faq-question flex items-center justify-between p-4 cursor-pointer" (click)="toggleAnswer(item)">
          <span class="text-sm font-semibold">{{ item.question }}</span>
          <ng-container *ngIf="!item.open">
            <img src="assets/img/icon/arrow-down.svg" class="h-6 w-6" />
          </ng-container>
          <ng-container *ngIf="item.open">
            <img src="assets/img/icon/arrow-up.svg" class="h-6 w-6" />
          </ng-container>
        </div>
        <div *ngIf="item.open" class="faq-answer text-sm p-4 text-justify bg-white border-t border-gray-300">
          <p>{{ item.answer }}</p>
        </div>
      </div>
    </div>
  </div>
</div>
