import { Component, Input, ElementRef, ViewChild, OnInit } from '@angular/core';
import { getDocument, GlobalWorkerOptions, PDFDocumentProxy, PDFPageProxy } from 'pdfjs-dist';

@Component({
  selector: 'app-pdf-preview',
  templateUrl: './pdf-preview.component.html',
  styleUrls: ['./pdf-preview.component.scss']
})
export class PdfPreviewComponent implements OnInit {
  @Input() pdfSrc: string | ArrayBuffer | null = null;
  @ViewChild('pdfContainer', { static: true }) pdfContainer!: ElementRef;

  private pdfDocument!: PDFDocumentProxy;

  ngOnInit() {
    if (this.pdfSrc) {
      this.loadPdf(this.pdfSrc);
    }
  }

  async loadPdf(pdfSrc: string | ArrayBuffer) {
    // Define o caminho do worker do PDF.js
    GlobalWorkerOptions.workerSrc = 'assets/pdf.worker.min.js';

    // Carrega o documento PDF
    const loadingTask = getDocument(pdfSrc);
    this.pdfDocument = await loadingTask.promise;

    // Renderiza a primeira página
    this.renderPage(1);
  }

  async renderPage(pageNumber: number) {
    const page = await this.pdfDocument.getPage(pageNumber);
    const scale = 1.5;
    const viewport = page.getViewport({ scale });

    // Prepara o canvas
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    if (context) {
      canvas.height = viewport.height;
      canvas.width = viewport.width;

      this.pdfContainer.nativeElement.innerHTML = ''; // Limpa o container
      this.pdfContainer.nativeElement.appendChild(canvas);

      // Renderiza a página
      const renderContext = {
        canvasContext: context,
        viewport: viewport
      };
      await page.render(renderContext).promise;
    }
  }
}
