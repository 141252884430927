<div class="w-full">
    <div class="tab-header flex border-b border-gray-300">
      <div *ngFor="let tab of tabs; let i = index"
           class="tab-header-item cursor-pointer py-2 px-4 bg-gray-200 border border-gray-300 rounded-tl rounded-tr"
           [class.bg-white]="isActive(i)"
           (click)="selectTab(i)">
        {{ tab }}
      </div>
    </div>
    <div class="tab-content p-4 border border-gray-300 rounded-b">
      <div *ngIf="isActive(0)">
        <p>Conteúdo da Tab 1</p>
      </div>
      <div *ngIf="isActive(1)">
        <p>Conteúdo da Tab 2</p>
      </div>
      <div *ngIf="isActive(2)">
        <p>Conteúdo da Tab 3</p>
      </div>
    </div>
  </div>
  