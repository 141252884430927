import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-text-card',
  templateUrl: './text-card.component.html',
  styleUrls: ['./text-card.component.scss'],
})
export class TextCardComponent {
  @Input() text: string | number | undefined;
}
