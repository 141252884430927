import { Component } from '@angular/core';
import * as Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';


HighchartsMore(Highcharts);


@Component({
  selector: 'app-bar-chart',
  templateUrl: './bar-chart.component.html',
  styleUrls: ['./bar-chart.component.scss']
})
export class BarChartComponent {
  Highcharts: typeof Highcharts = Highcharts;
  chartOptions: Highcharts.Options = {
    chart: {
      type: 'waterfall'
    },
    title: {
      text: 'Waterfall Chart Example'
    },
    xAxis: {
      categories: ['Start', 'Product Revenue', 'Service Revenue', 'Positive Balance', 'End']
    },
    yAxis: {
      title: {
        text: 'Total'
      }
    },
    series: [{
      type: 'waterfall',
      data: [
        { name: 'Start', y: 100 },
        { name: 'Product Revenue', y: -20 },
        { name: 'Service Revenue', y: 15 },
        { name: 'Positive Balance', isIntermediateSum: true },
        { name: 'End', isSum: true }
      ]
    }]
  };
}
