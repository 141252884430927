<app-user-layout>

    <div class="grid grid-cols-1">
        <div class="grid-col justify-center">
            <div class="flex items-center justify-center mb-8">
                <img src="assets/img/quadro.svg" width="100px" alt="Logo" class="mr-4">
                <div>
                    <h1 class="text-4xl font-extrabold text-left mb-4">
                        Conversa com Lua
                    </h1>
                    <p class="mt-5 mb-5 p-2 bg-[#fff] text-center rounded-xl font-bold text-[#00527E] m-2" 
                        (click)="refreshONB()"
                        style="border: 1px solid #00527E;">
                        <span class="cursor-pointer text-[small]"
                            *ngIf="is_loading==false"> 
                            Actualizar Guia
                        </span>

                        <svg aria-hidden="true" 
                            class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                            viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg"
                            *ngIf="is_loading==true">
                            <path
                                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                fill="currentColor" />
                            <path
                                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                fill="currentFill" />
                        </svg>
                    </p>
                    <p class="opacity-30"></p>    
                </div>

            </div>
        </div>
    </div>

    <div class="grid grid-cols-2 " *ngIf="onboard_prof!=null">
        <div class="grid-col ">
            <div class="text-left ">
                <h3 class="text-1xl font-extrabold text-left mb-0 mt-0">
                    GUIA R&Aacute;PIDO
                </h3>

                <p class="text-left mb-0 opacity-70 text-[small]">
                    Ajuste a Lua as suas necessidaes. Saiba mais acerca no <strong>Guia &amp; Ajuda</strong>.
                </p>
                <p class="text-left mb-0 opacity-70 text-[small]">
                    Desejamos que tenha optimo proveito do assistente.
                </p>

                <div class="mt-5 mb-5  flex flex-row gap-2">
                    <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36">
                        <img src="assets/img/icon/infocircle.svg" alt="Lua" />
                        <span class="cursor-pointer text-[small] p-2 bg-[#EAF2F2] text-[#00527E] rounded-xl m-2 border border-[#00527E]"
                            (click)="openHelpGuide()">
                            Guia &amp; Ajuda
                        </span>
                    </div>
                    <!-- <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 ">
                        <img src="assets/img/icon/Demo.svg" alt="Lua" />
                        <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]"
                            (click)="openMode('demo')">
                            Ver Demo
                        </span>
                    </div>-->
                    <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 ">
                        <img src="assets/img/icon/notehg.svg" alt="Lua" />
                        <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]"
                            (click)="openMode('examples')">
                            Exemplos
                        </span>
                    </div>
                    <div class="flex flex-col items-center justify-center rounded-xl bg-[#EAF2F2] w-36 h-36 ">
                        <img src="assets/img/icon/setting.svg" alt="Lua" />
                        <span class="cursor-pointer text-[small] rounded-xl p-2 bg-[#EAF2F2] text-[#00527E] m-2 border border-[#00527E]"
                            (click)="openMode('how-works')">
                            Como Funciona
                        </span>
                    </div>
                </div>
            </div>
        </div>

        <div class="grid-col ">
            <div [ngClass]="{
                'flex-row': onboard_prof.stm_stream && onboard_prof.stm_stream.length > 0,
                'flex-col': !(onboard_prof.stm_stream && onboard_prof.stm_stream.length > 0)
            }" class="flex  mb-4">

                <!-- Conversas -->
<div *ngIf="onboard_prof.stm_stream && onboard_prof.stm_stream.length > 0" class="bg-[#EAF2F2] rounded-xl p-2 w-[80%] ml-6 h-[300px] overflow-auto">
    <span class="flex flex-row gap-2 align-bottom font-bold">
        <img src="assets/img/icon/notehg.svg" alt="Lua" class="w-10 h-10" />
        Últimas Conversas
    </span>
    <hr />
    <div class="xl:flex rounded-xl flex-col">
        <div *ngFor="let memory_item of onboard_prof.stm_stream; let i = index"
            (click)="openMemConversation(memory_item)"
            class="gap-[1rem] pt-2 rounded-full rounded-br-none my-2 px-8 min-h-[4rem] cursor-pointer clickable-item">
            <p class="opacity-70" style="font-size:xx-small;">
                {{ memory_item['tms'] }}
            </p>
            <div class="text-[0.875rem] text-black">{{ memory_item['title'] }}</div>
            <p class="opacity-70" style="font-size:x-small;">
                {{ memory_item['answer'].slice(0, 55) + "..." }}
            </p>
        </div>
    </div>
</div>

<!-- Documentos -->
<div *ngIf="onboard_prof.recent_docs.length > 0" class="bg-[#F489141A] rounded-xl p-2 w-[80%] ml-6 h-[300px] overflow-auto">
    <span class="flex flex-row gap-2 align-bottom font-bold">
        <img src="assets/img/icon/folder-2.svg" alt="Lua" class="" />
        Documentos recentes ({{ onboard_prof.recent_docs.length }})
    </span>
    <hr />
    <div class="xl:flex rounded-xl flex-col">
        <div *ngFor="let doc of onboard_prof.recent_docs; let i = index"
            class="gap-[1rem] rounded-full rounded-br-none my-2 px-8 flex items-center cursor-pointer"
            (click)="openDoc(doc,i)">
            <div class="text-[0.875rem] text-black">{{ doc.realname }}</div>
            <p class="opacity-70" style="font-size:x-small;">
                {{ doc.tms + " # " + doc.fileid }}
            </p>
        </div>
    </div>
</div>

            </div>

            <div class="grid grid-cols-1">
                <div class="grid-col">
                    <div class="flex justify-end mb-4">
                        <p class="p-2 bg-[#fff] text-center rounded-xl text-[#00527E] m-2 cursor-pointer"
                            (click)="goWithHiddingGuide()"
                            style="border: 1px solid #00527E;">
                            Esconder Guia
                        </p>
                    </div>
                </div>
            </div>
            
        </div>
    </div>

    <div class="grid grid-cols-2" *ngIf="onboard_prof!=null">
        <div class="grid-col">

        </div>

        <div class="grid-col">
             <div *ngIf="onboard_prof.recent_docs.length===0">
                <div class="bg-[#F489141A] rounded-xl p-2  mb-4 -mt-3">

                    <span class="flex flex-row gap-2 align-bottom">
                        <img src="assets/img/icon/folder-2.svg" alt="Lua" class="" />
                        Documentos

                    </span>
                    <hr />
                    <div class="flex flex-col justify-center items-center mt-6">
                        <img src="assets/img/icon/document-text.svg" alt="Lua" class="w-28 h-28" />
                        <h3 class="text-sm  text-left mb-0 mt-5">
                            SEM DOCUMENTOS RECENTES
                        </h3>
                        <p class="opacity-70 text-xs">
                            <img class="h-[12px] w-[12px] color-[#fff]" src="assets/img/Vector.svg" alt=""
                                style="display:inline" />
                            Comece por caregar um fatura
                        </p>
                    </div>

                    <p class="mt-4 mb-5   rounded-xl p-2  text-[#00527E] m-2 border border-[#00527E]   ">
                        <span class="cursor-pointer p-2  rounded-xl text-sm   m-2 justify-center"
                            (click)="fileobj.click()">
                            <input type="file" class="hidden" #fileobj (change)="uploadFile($event)" />
                            Carregar documento
                        </span>
                    </p>
                </div>
            </div>
            <br /><br />

            <!--
            <div class="">
                <div>
                    <h3 class="text-2xl font-extrabold text-left mt-10 mb-4">
                        Sugest&otilde;es
                    </h3>
                    <app-recent-prompts [prompts]="onbd_prompts"></app-recent-prompts>
                </div>
            </div>
            -->
        </div>
    </div>

    <div class="grid grid-cols-1">
        <div class="grid-col">
            <div *ngIf="getSamples().length > 0">    
                <app-responses [content]="getSamples()"></app-responses>
            </div>
        </div>
    </div>

</app-user-layout>
