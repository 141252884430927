import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { ChatService } from 'src/app/services/chat.service';
import { LanguageService } from 'src/app/services/language.service';

@Component({
  selector: 'app-mobile-menu',
  templateUrl: './mobile-menu.component.html',
  styleUrls: ['./mobile-menu.component.scss'],
})
export class MobileMenuComponent implements OnInit {
  @Input()
  dark: boolean = false;
  @Input() sideMenu: any[] = [];
  authenticated: boolean = false;
  isMobileMenuOpen: boolean = false;
  indexOfColapse: number = -1;
  selectedLanguage: string = 'en'; 
  url?: string;
  languages = [
    { code: 'en', name: 'En', flag: 'assets/img/en.svg' },
    { code: 'pt', name: 'Pt', flag: 'assets/img/pt.svg' }
  ];

  toggleMobileMenu(): void {
    this.sideMenu.forEach((res) => {
      res.activeIcon = 'assets/img/icon/arrow-right-colapse-mobile.svg';
    });
    this.isMobileMenuOpen = !this.isMobileMenuOpen;
    // Optionally, you can prevent scrolling when the menu is open by adding the following line:
    // document.body.style.overflow = this.isMobileMenuOpen ? 'hidden' : 'auto';
  }
  constructor(
    private _router: Router,
    private keycloakService: KeycloakService,
    private chatService: ChatService,
    private languageService: LanguageService,
    
  ) {this.url = _router.url.split('/')[1];}

  toggleQuestion(index: number) {
    if (this.indexOfColapse !== -1 && this.indexOfColapse !== index) {
      this.sideMenu[this.indexOfColapse].activeIcon =
        'assets/img/icon/arrow-right-colapse-mobile.svg';
    }
    if (this.indexOfColapse === index) {
      this.sideMenu[this.indexOfColapse].activeIcon =
        'assets/img/icon/arrow-right-colapse-mobile.svg';
      this.indexOfColapse = -1;
    } else {
      this.sideMenu[index].activeIcon =
        this.sideMenu[index].activeIcon ===
        'assets/img/icon/arrow-down-colapse-mobile.svg'
          ? 'assets/img/icon/arrow-right-colapse-mobile.svg'
          : 'assets/img/icon/arrow-down-colapse-mobile.svg';
      this.indexOfColapse = index;
    }
  }
  onMenuClick(question: string) {
    this.chatService.sendData(question);
    this.isMobileMenuOpen = false;
  }
  async isAuthenticaded() {
    this.authenticated = await this.keycloakService.isLoggedIn();
  }
  ngOnInit(): void {
    // this.sideMenu.forEach((res) => {
    //   res.activeIcon = 'assets/img/icon/arrow-right-colapse-mobile.svg';
    // });
    this.selectedLanguage = this.languageService.getCurrentLanguage();
    
    this.isAuthenticaded();
    const button = document.querySelector('#menu-button');
    const close = document.querySelector('#close-menu');
    const menu = document.querySelector('#menu');
    const nav = document.querySelector('#m-nav');
    if (button)
      button.addEventListener('click', () => {
        if (menu) menu.classList.toggle('hidden');
        if (nav) {
          nav.classList.toggle('!bg-[#0C1026]');
          nav.classList.toggle('h-screen');
          nav.classList.toggle('items-center');
          nav.classList.toggle('flex');
          nav.querySelector('#login')?.classList.toggle('hidden');
          nav.querySelector('#logsign')?.classList.toggle('!hidden');
          button.classList.add('hidden');
        }
      });

    if (close)
      close.addEventListener('click', () => {
        if (menu) menu.classList.toggle('hidden');
        if (nav) {
          nav.classList.toggle('!bg-[#0C1026]');
          nav.classList.toggle('h-screen');
          nav.classList.toggle('items-center');
          nav.classList.toggle('flex');
          nav.querySelector('#login')?.classList.toggle('hidden');
          nav.querySelector('#logsign')?.classList.toggle('!hidden');
          if (button) button.classList.remove('hidden');
        }
      });
  }
  goToRegister() {
    this.keycloakService.register();
  }
  logout() {
    localStorage.removeItem("AUTH_CREDS");
    this._router.navigate(['/auth/login']); 
  }
  switchLanguage(language: string) {
    this.languageService.switchLanguage(language);
  }

  onLanguageChange(event: Event) {
    const selectElement = event.target as HTMLSelectElement;
    this.languageService.switchLanguage(selectElement.value);
  }
  getFlag(code: string): string {
    const lang = this.languages.find(language => language.code === code);
    return lang ? lang.flag : '';
  }
  
}
