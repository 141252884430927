<div class="
w-full
fixed z-[9999]
     md:hidden
      antialiased
      bg-gradient-to-r
      from-pink-300
      via-purple-300
      to-indigo-400
    ">
  <header>
    <nav class=" py-2
          flex flex-wrap
          items-center
          justify-between
          w-full
          p2-4
          md:py-0
          px-4
          text-lg text-white   
!bg-[#fff]
        " id="m-nav">
      <div id="hamburger-menu" class="md:hidden" (click)="toggleMobileMenu()">
        <img src="assets/img/icon/menu-icon.svg" alt="Menu">
      </div>
      <div class="h-screen rounded-r-[15px] rounded-b-[15px] overflow-y-auto bg-[#115290] pt-8" id="mobile-menu"
        [ngClass]="isMobileMenuOpen? 'active block' : 'none'">
        <div class="flex justify-between  items-center mt-[15px] px-[15px] text-white">
          <span (click)="toggleMobileMenu()"><img src="assets/img/icon/menu-icon-white.svg" alt=""></span>
        </div>
        <div class="md:flex mt-4 items-center  px-[15px] text-black">
          <div *ngFor="let item of sideMenu; let i = index">
            <div class=" border-b-2 border-b-[#2869A7] ">
              <div
                class="flex justify-between items-center cursor-pointer text-[12px]  text-white font-normal md:hover:bg-[#9EB0C1] md:hover:rounded-[8px] md:hover:p-[4px] md:hover:text-[#fff]  p-[4px] "
                [routerLink]="item.route">
                <span class="text-[14px] font-semibold">{{item.title}}</span>
                <span><img [src]="item.whiteIcon" alt=""></span>
              </div>
            </div>
          </div>
        </div>
        <div class="md:flex mt-[27rem] items-center px-[15px] text-black">
          <div class="border-b-2 border-b-[#2869A7]">
            <div class="
              flex justify-between items-center cursor-pointer
              text-[12px] text-white font-normal
              md:hover:bg-[#9EB0C1] md:hover:rounded-[8px]
              md:hover:p-[4px] md:hover:text-[#fff] p-[4px]" (click)="logout()">
              <span class="text-[14px] font-semibold">Logout</span>
              <span><img src="assets/img/logoutWhite.svg" alt=""></span>
            </div>
          </div>
        </div>
      </div>
      <div id="login" class="flex flex-grow md:hidden space-x-16 justify-center ">
        <div [routerLink]="'/'" class=" z-50">
          <img alt="icon" src="assets/img/logoNosi.svg">
        </div>
        <div class=" flex flex-row" *ngIf="url === ''">
          <div class="inset-y-0 right-5  pointer-events-none rounded-full">
            <img [src]="getFlag(selectedLanguage)" alt="Selected Language Flag" class="w-10 h-10 rounded-full" />
          </div>
          <select class="text-black py-2" (change)="onLanguageChange($event)" [(ngModel)]="selectedLanguage">
            <option *ngFor="let lang of languages" [value]="lang.code">{{ lang.name }}</option>
          </select>

        </div>
      </div>
      <div id="logsign" class="!hidden">
        <div class="flex gap-8 my-4">
          <app-rounded-icon-buttom [routerLink]="'/'"
            [buttonData]="{iconUrl:'assets/img/icon/logo.svg',btnClass:'z-50'}" />
          <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/search.svg',btnClass:''}" />
          <app-rounded-icon-buttom [buttonData]="{iconUrl:'assets/img/icon/cart.svg',btnClass:''}" />
          <div class="flex-grow"></div>
          <span id="close-menu"><app-rounded-icon-buttom
              [buttonData]="{iconUrl:'assets/img/icon/cross.svg',btnClass:''}" /></span>
        </div>
      </div>
    </nav>
  </header>
</div>