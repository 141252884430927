<div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
    <div *ngFor="let card of cards" class="bg-white p-4 shadow-md rounded-lg">
      <img [src]="card.imageUrl" alt="Card Image" class="w-full h-48 object-cover rounded-lg mb-4">
      <h2 class="text-xl font-semibold text-gray-800 mb-2">{{ card.title }}</h2>
      <p class="text-gray-600 mb-4">{{ card.description }}</p>
      <div class="flex justify-center space-x-4">
        <button *ngFor="let button of card.buttons" class="px-4 py-2 bg-blue-500 text-white rounded-md"
                (click)="button.action()">{{ button.text }}</button>
      </div>
    </div>
  </div>
  