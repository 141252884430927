import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-welcome-to-lua',
  templateUrl: './welcome-to-lua.component.html',
  styleUrls: ['./welcome-to-lua.component.scss']
})
export class WelcomeToLuaComponent {
  constructor(private router: Router) { }
  startExploring() {
    this.router.navigate(['/auth/login']);
  }
  goToGuide() {
    this.router.navigate(['/guide']);
  }
}
