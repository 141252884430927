import { Component, OnInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-video',
  templateUrl: './video.component.html',
  styleUrls: ['./video.component.scss']
})
export class VideoComponent implements OnInit, OnDestroy {
  private mediaRecorder: MediaRecorder | null = null;
  private chunks: Blob[] = [];
  public isRecording = false;
  public videoURL: string | null = null;
  public videoStream: MediaStream | null = null;
  @ViewChild('videoPlayer') videoPlayer: ElementRef<HTMLVideoElement> | undefined;

  constructor() {}

  ngOnInit(): void {}

  ngOnDestroy(): void {
    if (this.videoStream) {
      this.videoStream.getTracks().forEach(track => track.stop());
    }
  }

  startRecording(): void {
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          this.videoStream = stream;
          console.log('Stream obtained:', stream);

          // Exibir vídeo ao vivo
          if (this.videoPlayer && this.videoPlayer.nativeElement) {
            this.videoPlayer.nativeElement.srcObject = stream;
          } else {
            console.error('Video player element not found.');
          }

          this.mediaRecorder = new MediaRecorder(stream);
          this.mediaRecorder.ondataavailable = (event) => {
            this.chunks.push(event.data);
          };
          this.mediaRecorder.onstop = () => {
            const blob = new Blob(this.chunks, { type: 'video/webm' });
            this.videoURL = URL.createObjectURL(blob);
            this.chunks = [];
          };
          this.mediaRecorder.start();
          this.isRecording = true;
        })
        .catch(error => console.error('Error accessing media devices:', error));
    } else {
      console.error('getUserMedia not supported on your browser!');
    }
  }

  stopRecording(): void {
    if (this.mediaRecorder && this.isRecording) {
      this.mediaRecorder.stop();
      this.isRecording = false;
    }
  }

  downloadVideo(): void {
    if (this.videoURL) {
      const a = document.createElement('a');
      a.href = this.videoURL;
      a.download = 'recorded-video.webm';
      a.click();
    }
  }
}