import { Component, OnInit, Input } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { ChatService } from 'src/app/services/chat.service';

@Component({
  selector: 'app-luaxapps',
  templateUrl: './luaxapps.component.html',
  styleUrls: ['./luaxapps.component.scss']
})
export class LuaxappsComponent  implements OnInit {
    is_error:boolean = false
    is_loading:boolean = false
    onboard_prof:any = null
    onbd_prompts:any[] = []

    constructor(
        private router: Router,
        private chatService: ChatService
    ) {
        // console.info("[home] chatService: ", chatService);
    }

    AV_SOON(msg:any) {
    }

    OpenApp(appName:any){
        this.router.navigate(['/apps/'+appName+"/index/0.0.1-SNAPSHOT"]);        
    }

    openPage(name:string) {
        this.router.navigate([name]);        
    }

    openHelpGuide() {
        this.router.navigate(['/guide']);        
    }

    openMode(mode:any) {
        // console.info("OPEN-MODE: ", mode)
    }

    ngOnInit() {
        // this.ExecuteOnboard()
    }
}



