<div>
    <p class="mt-4 mb-5" (click)="goBack()">
        <span class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
            style="border: 1px solid rgb(0, 82, 126);">
            VOLTAR
        </span>
    </p>
    <img src="assets/img/apps/ODOO.webp" alt="Odoo Logo" class="w-40 h-50 pull-left " />

    <h2 class="text-left opacity-70">
        <span class="cursor-pointer">Lua Apps</span>
        /
        <span class="text-[#00527E]">
            ODOO
        </span>
    </h2>

</div>

<div class="mt-4">

    <div class="w-full">
        <div class="tab-header flex border-b border-gray-300">

            <div class="tab-header-item cursor-pointer py-2 px-4  border  rounded-tl rounded-tr"
                style="background-color:#00527E!important;color:#fff;">
                Faturas
            </div>
            <div
                class="tab-header-item cursor-pointer py-2 px-4 bg-gray-200 border border-gray-300 rounded-tl rounded-tr">
                Contabilidade
            </div>
        </div>

        <div class=" p-2 tab-content md:p-5 border border-gray-300 rounded-b">

            <div class="grid grid-cols-1">
                <div class="grid-col">
                    <div class="grid grid-cols-3">
                        <div class="grid-col">
                            <p class="mt-2 mb-2 text-bold">
                                Faturas / Invoices
                                |
                                <span
                                    (click)="FetchInvoices(false)"
                                    class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
                                    style="border: 1px solid rgb(0, 82, 126);">
                                    ACTUALIZAR
                                </span>
                            </p>                           
                        </div>

                        <div class="grid-col text-right">
                            <div >
                                <form [formGroup]="filterForm" 
                                    (ngSubmit)="filterInvoices()">                           
                                    <input type="text" 
                                        placeholder="Pesquisar..." 
                                        formControlName="query"
                                        required
                                        class="border border-gray-300 px-5 py-1 rounded-full" 
                                        style="padding-left: 40px;" />
                                    <select formControlName="state" 
                                        required>
                                        <option value="draft">Draft</option>
                                        <option value="payed">Pago</option>
                                    </select>
                                    <img src="assets/img/icon/search.svg" alt=""  c
                                        style="position: absolute; top: 38.5%; left: 560px; transform: translateY(-50%);" 
                                    />
                                </form>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3"></div>

                    <app-alert type="warning" 
                        message="Esta requisicao parece estar a demorar." 
                        *ngIf="is_taking_long">
                    </app-alert>

                    <app-alert type="error" message="Nao foi possivel carregar os detalhes" 
                        *ngIf="is_error">
                    </app-alert>

                    <app-alert type="error" message="Nao foi possivel concluir o filtro" 
                        *ngIf="is_filter_error">
                    </app-alert>
                </div>

                <div class="grid-col">
                    <div class="" 
                        *ngIf="is_loading==true">
                        <div class="flex items-center rounded-xl rounded-tl-none w-[7rem] ">
                            <div role="status">
                                <svg aria-hidden="true"
                                    class="inline w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
                                    viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                                        fill="currentColor" />
                                    <path
                                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                                        fill="currentFill" />
                                </svg>
                                <span class="sr-only">Loading...</span>
                            </div>
                        </div>
                    </div>

                    <div class="mt-3" *ngIf="objectCache && objectCache.invoices">
                        <table class="text-sm " style="width:100%">
                            <thead>
                                <tr class="bg-gray-300 bg-opacity-60 rounded-2xl pb-4">
                                    <th class="pb-2 pt-2 pl-2">Nome</th>
                                    <th>Design.</th>
                                    <th>Data</th>
                                    <th class="text-center">Ref de Pagamento</th>
                                    <th class="text-center">Ref de Cliente</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody class="mb-4 mt-2">
                                <tr class="cursor-pointer pb-10 hover:bg-[#F0F0F0]"
                                    *ngFor="let invoice of objectCache.invoices.records">
                                    <td class="pb-2 pt-2 pl-2">
                                        <span class="text-sm" style="color:#714B67">#{{ invoice.id }}</span>
                                        {{ invoice['name'].slice(0,15) }}
                                    </td>
                                    <td> {{ invoice['invoice_partner_display_name'].slice(0,15) }} </td>
                                    <td> {{ invoice['invoice_date'] }}</td>
                                    <td class="text-center"> {{ invoice['payment_reference'] }} </td>
                                    <td class="text-center"> {{ invoice['ref'] }} </td>
                                    <td class="text-center">
                                        <p class="mt-2 mb-2"
                                            *ngIf="allow_add_bill&&!invoice.is_add&&!is_xml_model_ready"
                                            (click)="addToList(invoice)">
                                            <span
                                                class="cursor-pointer text-[small] p-2 rounded-xl font-bold text-[#fff] m-2 ml-0"
                                                style="background-color: rgb(0, 82, 126);border: 1px solid rgb(0, 82, 126);">
                                                ADICIONAR A LISTA
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <p class=" text-sm pt-5">
                            <span class="opacity-70">
                                A pesquisa retornou {{ objectCache.invoices.total_count }} faturas.
                            </span>
                            <span style="color:#333">
                                Mostrando {{ objectCache.invoices.records.length }}
                            </span>
                        </p>
                    </div>

                </div>
            </div>

        </div>

        <div class="tab-header flex border-b border-gray-300 mt-8">
            <div class="tab-header-item cursor-pointer py-2 px-4  border  rounded-tl rounded-tr"
                style="background-color:#00527E!important;color:#fff;">
                Modelo 106
            </div>
        </div>

        <div class=" p-2 tab-content md:p-5 border border-gray-300 rounded-b">

            <div class="grid grid-cols-2">
                <div class="grid-col pt-20 pb-20 text-center" *ngIf="model106Conf===null">
                    <h2 class="text-4xl">Criar um modelo 106.</h2>
                    <p style="font-size:small" class="opacity-70">
                        Para criar um modelo 106. clique em come&ccedil;ar.
                    </p>
                    <p class="mt-2 mb-2">
                        <span
                            class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
                            (click)="beginModel106()" style="border: 1px solid rgb(0, 82, 126);">
                            COME&Ccedil;AR
                        </span>
                    </p>
                </div>

                <div class="grid-col text-center pt-20 pb-20" *ngIf="model106Conf!==null && !is_xml_model_ready">
                    <h2 class="text-4xl">Mais 1 passo</h2>
                    <p style="font-size:small" class="opacity-70">
                        Selecione as faturas em cima, clique em Continuar quando estiver pronto.
                    </p>
                    <p class="mt-2 mb-2">
                        <span
                            class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
                            (click)="cancelModel106()" style="border: 1px solid rgb(0, 82, 126);">
                            CANCELAR
                        </span>
                    </p>
                </div>

                <div class="grid-col text-center pt-20 pb-20" *ngIf="model106Conf!==null && is_xml_model_ready">
                    <h2 class="text-4xl">Formulario DPR 106</h2>
                    <p style="font-size:small" class="opacity-70">
                        Preencha, fa&ccedil;a ajustes e j&aacute; est&aacute;.
                    </p>
                    <p class="mt-2 mb-2">
                        <span
                            class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
                            (click)="backModel106()" style="border: 1px solid rgb(0, 82, 126);">
                            VOLTAR
                        </span>
                    </p>
                </div>

                <div class="grid-col text-center mb-2" style="max-height:500px;overflow: hidden;overflow-y: scroll;"
                    *ngIf="model106Conf&&model106Conf.input_bills && !is_xml_model_ready">
                    <!-- <h3 >Faturas Selecionadas</h3> -->

                    <div class="mt-0">
                        <table class="text-sm " style="width:100%">
                            <thead>
                                <tr class="bg-gray-300 bg-opacity-60 rounded-2xl pb-4">
                                    <th class="pb-2 pt-2 pl-2">Nome</th>
                                    <th>Design.</th>
                                    <th class="text-center">Actions</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr class="cursor-pointer pb-10 hover:bg-[#F0F0F0]"
                                    *ngFor="let Key of Object.keys(model106Conf.input_bills)">
                                    <td class="pb-2 pt-2 pl-2">
                                        <span class="text-sm" style="color:#714B67">
                                            {{ model106Conf.input_bills[Key].id }}
                                        </span>
                                    </td>

                                    <td>
                                        {{
                                        model106Conf.input_bills[Key]['invoice_partner_display_name'].slice(0,15)
                                        }}
                                    </td>

                                    <td class="text-center">
                                        <p class="mt-2 mb-2"
                                            *ngIf="allow_add_bill && model106Conf.input_bills[Key] && !model106Conf.input_bills[Key].is_deleted"
                                            (click)="removeFromList(Key)">
                                            <span
                                                class="cursor-pointer text-[small] p-2 rounded-xl font-bold text-[#fff] m-2 ml-0"
                                                style="background-color: rgb(0, 82, 126);border: 1px solid rgb(0, 82, 126);">
                                                REMOVER
                                            </span>
                                        </p>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <hr />
                        <p class=" text-sm pt-5">
                            <span class="opacity-70 text-sm" style="">
                                Selecionadas {{ model106Conf.count_c }}
                            </span>
                        </p>

                        <p class="mt-2 mb-2" *ngIf="model106Conf.count_c>0">
                            <span
                                class="cursor-pointer text-[small] p-2 bg-[#fff] rounded-xl font-bold text-[#00527E] m-2 ml-0"
                                (click)="continueModel106()" style="border: 1px solid rgb(0, 82, 126);">
                                CONTINUAR
                            </span>
                        </p>

                        <p class="mt-2 mb-2 opacity-70 textsm" *ngIf="model106Conf.count_c==0">
                            Para criar o modelo vai precisar de pelo menos uma (1) fatura.
                        </p>
                    </div>
                </div>

                <div class="grid-col" *ngIf="is_xml_model_ready && xmlReport">
                    <app-dpr-106 [xmlReport]="xmlReport">
                    </app-dpr-106>
                </div>

            </div>

        </div>
    </div>
</div>