<!-- welcome.component.html -->
<ng-container *ngIf="isLoggedIn; else guestContent">
    <!-- Exibir quando o usuário estiver logado -->
    <app-user-layout>
        <div>
            <div
                class="w-full md:w-[85%] grid grid-cols-1 md:grid-cols-2 place-content-center text-left text-[#414141] min-h-screen sm:mt-1   lg:-mt-10">
                <div class="hidden md:block"></div>

                <div class="col-span-1 md:col-span-2 pt-10 pb-10 ">
                    <!-- Conteúdo quando o usuário está logado -->
                    <div class="mb-5">
                        <img src="assets/img/im blue.jpg" alt="Logo"
                            class="rounded-full w-10 h-10 border-2 pull-left border-blue-300" />
                        <h1 class="text-4xl text-left mb-6">Bem-vindo</h1>
                        <p class="text-[#333] mb-4 font-bold">Guia &amp; Ajuda</p>
                        <p class="font-Poppins text-justify">
                            Lua é uma AI projetada para optimizar a precisão e a eficiência em processos contabilístico
                            financeiro. Uma parceira essencial para o sucesso, crescimento, resiliência através de um
                            ambiente eficiente e estratégico para a gestão financeira a prova do futuro.
                        </p>
                        <br />
                        <p class="font-Poppins text-justify">
                            Acelerar o trabalho do contabilista, proporcionando uma otimização de até 90% nos processos
                            contábil.
                        </p>
                    </div>

                    <p class="text-[#333] mt-4 mb-4 font-bold">Lua Opera em fases distintas:</p>
                    <div class="font-poppins mb-8">
                        <!-- Fases do processo -->
                        <div class="flex items-start mb-6">
                            <img src="assets/img/icon/um.svg" alt="Logo" class="mr-3 self-start" />
                            <p class="mt-2 text-justify"> Fase de Recolha e Tratamento dos Dados:<br />
                                - Automatiza a coleta e processamento de dados, como faturas e lançamentos no sistema da
                                empresa. Durante este processo, Lua valida os dados e analisa sua coerência em termos
                                contabilisticos, assim como as responsabilidades e compromissos da empresa com
                                fornecedores, baseando-se em históricos de faturação anteriores.<br />
                                - Realiza validação dos dados do NIF (Número de Identificação Fiscal), utilizando APIs
                                do NIF para verificar a correção das informações. Em caso de discrepâncias, Lua não
                                registra os dados e comunica os responsáveis para correção.
                            </p>
                        </div>

                        <div class="flex items-start mb-6">
                            <img src="assets/img/icon/dois.svg" alt="" class="mr-3 self-start">
                            <p class="mt-2 text-justify"> Fase de Criação de Relatórios e Notas:<br />
                                - Gera relatórios e notas contábeis de maneira rápida e precisa, utilizando os dados
                                previamente coletados e validados. Esta etapa inclui a análise contabilística sobre o
                                sistema, gerando relatórios de contas, balancetes e avaliando o desempenho da
                                empresa.<br />
                                - Faz análises detalhadas desses relatórios para os gestores e decisores, oferecendo
                                sugestões estratégicas e orientações para melhores caminhos a seguir.
                            </p>
                        </div>

                        <div class="flex items-start">
                            <img src="assets/img/icon/tres.svg" alt="" class="mr-3 self-start">
                            <p class="mt-2 text-justify"> Fase de Entregáveis:<br />
                                - Realiza revisões detalhadas dos documentos gerados e solicita autorização antes de
                                submetê-los, garantindo precisão e conformidade com as normas contábeis e legais.<br />
                                - Entrega os documentos e relatórios necessários às entidades tributárias, assegurando o
                                cumprimento das exigências legais do país em questão.
                            </p>
                        </div>
                    </div>

                    <div class="font-poppins mb-8">
                        <p class="text-[#333] mt-4 mb-4 font-bold">Objectivos</p>
                        <p class="text-justify">
                            O objectivo principal é rentabilizar, aumentar significativamente a produtividade e a
                            eficiência nas várias etapas do processo contábil. Lua se torna uma parceira essencial para
                            o sucesso do seu escritório contábil, proporcionando um ambiente mais eficiente e
                            estratégico para a gestão financeira da empresa.
                        </p>
                    </div>

                    <div>
                        <p class="text-[#333] mt-4 mb-4 font-bold ml-5">Como Funciona:
                            <app-faq></app-faq>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </app-user-layout>
</ng-container>

<!-- Template alternativo para quando o usuário não está logado -->
<ng-template #guestContent>
    <div
        class="w-full md:w-[80%] grid grid-cols-1 md:grid-cols-2 place-content-center text-left text-[#414141] min-h-screen sm:mt-1 ml-[40px]  gap-12 ">
        <div class="fixed">
            <div class=" hidden lg:flex flex-row items-center justify-center -ml-10 ">
                <img src="assets/img/icon/banner2.svg" alt="" class="w-15 h-[90%]">
            </div>
            <div class=" flex justify-center ml-80 -mt-14 z-index-20 ">
                <button (click)="startExploring()"
                  class="px-4 py-2 text-white bg-blue-600 hover:bg-blue-700 rounded-3xl transition duration-300">
                  Login
                </button>
              </div>
        </div>

        <div class="col-span-1 md:col-span-2 pt-10 pb-10 ml-[600px]">
            <div class="mb-5">
                <img src="assets/img/im blue.jpg" alt="Logo"
                    class="rounded-full w-10 h-10 border-2 pull-left border-blue-300" />
                <h1 class="text-4xl text-left mb-6">Bem-vindo</h1>
                <p class="text-[#333] mb-4 font-bold">Guia &amp; Ajuda</p>

                <p class="font-Poppins text-justify">
                    Lua é uma AI projetada para optimizar a precisão e a eficiência em processos contabilístico
                    financeiro. Uma parceira essencial para o sucesso, crescimento, resiliência através de um ambiente
                    eficiente e estratégico para a gestão financeira a prova do futuro.
                </p>
                <br />
                <p class="font-Poppins text-justify">
                    Acelerar o trabalho do contabilista, proporcionando uma otimização de até 90% nos processos
                    contábil.
                </p>
            </div>

            <p class="text-[#333] mt-4 mb-4 font-bold">Lua Opera em fases distintas:</p>
            <div class="font-poppins mb-8">
                <!-- Fases do processo -->
                <div class="flex items-start mb-6">
                    <img src="assets/img/icon/um.svg" alt="Logo" class="mr-3 self-start" />
                    <p class="mt-2 text-justify"> Fase de Recolha e Tratamento dos Dados:<br />
                        - Automatiza a coleta e processamento de dados, como faturas e lançamentos no sistema da
                        empresa. Durante este processo, Lua valida os dados e analisa sua coerência em termos
                        contabilisticos, assim como as responsabilidades e compromissos da empresa com fornecedores,
                        baseando-se em históricos de faturação anteriores.<br />
                        - Realiza validação dos dados do NIF (Número de Identificação Fiscal), utilizando APIs do NIF
                        para verificar a correção das informações. Em caso de discrepâncias, Lua não registra os dados e
                        comunica os responsáveis para correção.
                    </p>
                </div>

                <div class="flex items-start mb-6">
                    <img src="assets/img/icon/dois.svg" alt="" class="mr-3 self-start">
                    <p class="mt-2 text-justify"> Fase de Criação de Relatórios e Notas:<br />
                        - Gera relatórios e notas contábeis de maneira rápida e precisa, utilizando os dados previamente
                        coletados e validados. Esta etapa inclui a análise contabilística sobre o sistema, gerando
                        relatórios de contas, balancetes e avaliando o desempenho da empresa.<br />
                        - Faz análises detalhadas desses relatórios para os gestores e decisores, oferecendo sugestões
                        estratégicas e orientações para melhores caminhos a seguir.
                    </p>
                </div>

                <div class="flex items-start">
                    <img src="assets/img/icon/tres.svg" alt="" class="mr-3 self-start">
                    <p class="mt-2 text-justify"> Fase de Entregáveis:<br />
                        - Realiza revisões detalhadas dos documentos gerados e solicita autorização antes de
                        submetê-los, garantindo precisão e conformidade com as normas contábeis e legais.<br />
                        - Entrega os documentos e relatórios necessários às entidades tributárias, assegurando o
                        cumprimento das exigências legais do país em questão.
                    </p>
                </div>
            </div>

            <div class="font-poppins mb-8">
                <p class="text-[#333] mt-4 mb-4 font-bold">Objectivos:</p>
                <p class="text-justify">
                    O objectivo principal é rentabilizar, aumentar significativamente a produtividade e a eficiência nas
                    várias etapas do processo contábil. Lua se torna uma parceira essencial para o sucesso do seu
                    escritório contábil, proporcionando um ambiente mais eficiente e estratégico para a gestão
                    financeira da empresa.
                </p>
            </div>

            <div>
                <p class="text-[#333] mt-4 mb-4 font-bold ml-5">Como Funciona:
                    <app-faq></app-faq>
                </p>
            </div>

        </div>
    </div>
</ng-template>