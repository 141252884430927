import { Component, OnInit } from '@angular/core';
import { Chart, registerables } from 'chart.js';

@Component({
  selector: 'app-stock-chart',
  templateUrl: './stock-chart.component.html',
  styleUrls: ['./stock-chart.component.scss']
})
export class StockChartComponent implements OnInit {

  public chart: any;

  constructor() {
    Chart.register(...registerables);
  }

  ngOnInit(): void {
    this.createChart();
  }

  createChart(): void {
    this.chart = new Chart('stockChart', {
      type: 'line',
      data: {
        labels: Array.from({ length: 30 }, (_, i) => `${i + 1} Min`), // Labels para cada minuto
        datasets: [{
          label: 'Preço das Ações',
          data: this.generateRandomData(30), // Gera dados aleatórios para 30 minutos
          fill: false,
          borderColor: 'red',
          tension: 0.1
        }]
      },
      options: {
        scales: {
          x: {
            title: {
              display: true,
              text: 'Tempo'
            }
          },
          y: {
            title: {
              display: true,
              text: 'Preço (USD)'
            },
            suggestedMin: 100,
            suggestedMax: 200
          }
        }
      }
    });
  }

  generateRandomData(points: number): number[] {
    const data = [];
    let lastValue = 150; // Valor inicial

    for (let i = 0; i < points; i++) {
      lastValue += Math.random() * 10 - 5; // Variação aleatória
      data.push(Math.max(100, Math.min(lastValue, 200))); // Garante que os valores estejam dentro do intervalo
    }

    return data;
  }
}
